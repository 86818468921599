import { Page } from '../Page';

import './CommunityPage.scss';

export function CommunityPage() {
  return (
    <Page title="Community" className="community-page container py-2">
      <p>TODO redirect to discord</p>
    </Page>
  );
}
