import React from 'react';
import debug from 'debug';
import { useAppDispatch, useAppState } from '../../context/AppContext';
import { UserAccount } from '../../common/UserAccount';
import { useUserState } from '../../services/user/UserContext';
import { UserPermission } from '../../common/UserPermission';
import { UserService } from '../../services/user/UserService';
import { useUserService } from '../../services/user/UserServiceContext';
import { Page } from '../Page';
import { Alert } from '../../components/alerts/Alert';
import { TransferModal, TransferType } from '../../components/transfer-modal/TransferModal';

import './AccountPage.scss';

const log = debug('app:pages:account:AccountPage');

type InternalAccountProps = {
  userState: UserAccount,
  userService: UserService
};

type AccountState = {
  loading: boolean,
  chainBalance: string,
  transfer: TransferType | undefined
};

type Resolve = () => void;

class InternalAccountPage extends React.Component<InternalAccountProps, AccountState> {
  private userStateResolves: Resolve[] = [];
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      chainBalance: '...',
      transfer: undefined
    };

    this.loadAccount = this.loadAccount.bind(this);
    this.linkAccount = this.linkAccount.bind(this);
    this.depositIntoWallet = this.depositIntoWallet.bind(this);
    this.withdrawFromWallet = this.withdrawFromWallet.bind(this);
    this.dismissTransfer = this.dismissTransfer.bind(this);
  }
  componentDidMount() {
    this.load();
  }
  componentDidUpdate() {
    // this.load();
  }

  componentWillUnmount() {

  }

  async load(force = false) {
    if (!this.props.userState.loggedIn) { return; }
    this.setState({ loading: true });
    await this.props.userService.checkIfLinked(force);
    this.setState({ loading: false });
    this.loadChainBalance(); // purposely not using await
  }

  async loadChainBalance() {
    try {
      this.setState({ chainBalance: 'Loading...' });
      const chainBalance = await this.props.userService.fetchChainBalance();
      this.setState({ chainBalance });
    } catch (e) {
      log('error loading from chain balance:', e);
      this.setState({ chainBalance: 'n/a' });
    }
  }

  linkAccount() {
    this.props.userService.link();
  }

  loadAccount() {
    this.load(true);
  }

  depositIntoWallet() {
    this.setState({ transfer: TransferType.Deposit });
  }

  withdrawFromWallet() {
    this.setState({ transfer: TransferType.Withdraw });
  }

  dismissTransfer() {
    this.setState({ transfer: undefined });
  }

  // private waitForLogin(): Promise<void> {
    
  // }

  render() {
    const checked = this.props.userState.checked;
    const linked = this.props.userState.linked;
    const balance = this.props.userState.balance;
    const chainBalance = this.state.chainBalance;
    const nfts = this.props.userState.nfts;
    const nftsStr = !nfts ? 'n/a' :
      nfts.length === 0 ? '[empty]' :
        nfts.join(', ');
    const permissions = this.props.userState.permissions;
    const permissionsStr = !permissions ? 'n/a' :
      permissions.length === 0 ? '[empty]' :
        permissions.join(', ');
    return (
      <Page title="Account" className="account container py-2" restricted={[UserPermission.User]}>
        {!checked ? (
          <Alert.Info>Loading...</Alert.Info>
        ) : (
          <>
            {!linked && (
              <Alert.Warning>Account not linked yet.</Alert.Warning>
            )}
            <div>
              <button className="btn btn-primary" disabled={linked} onClick={this.linkAccount}>Link Account</button>
              <button className="btn btn-secondary ms-2" onClick={this.loadAccount}>Reload Details</button>
              <button className="btn btn-success ms-2" onClick={this.depositIntoWallet}>Deposit into Wallet</button>
              <button className="btn btn-info ms-2" onClick={this.withdrawFromWallet}>Withdraw from Wallet</button>
            </div>
            <div>
              {this.state.loading ? (
                <p>Loading account...</p>
              ) : (
                <ul>
                  <li>Checked: {checked ? 'Yes' : 'No'}</li>
                  <li>Linked: {linked ? 'Yes' : 'No'}</li>
                  <li>Platform Balance: {balance ?? 'n/a'}</li>
                  <li>Chain Balance: {chainBalance}</li>
                  <li>nfts: {nftsStr}</li>
                  <li>Permissions: {permissionsStr}</li>
                </ul>
              )}
            </div>
            <TransferModal show={!!this.state.transfer} transferType={this.state.transfer} onDismiss={this.dismissTransfer} />
          </>
        )}
      </Page>
    )
  }
}

export function AccountPage() {
  const appState = useAppState();
  const appDispatch = useAppDispatch();
  const userState = useUserState();
  const userService = useUserService();

  return (
    <InternalAccountPage
      userState={userState}
      userService={userService}
    />
  );
}
