import { debug } from 'debug';
import Decimal from 'decimal.js';
import React, { Dispatch } from 'react';
import { UserAccount } from '../../common/UserAccount';
import { UserPermission } from '../../common/UserPermission';
import { mockensPlaces } from '../../consts';
import { Action } from '../../context/GenerateContext';
import { MetadataService } from '../metadata/MetadataService';
import { createLoginScreen, destroyLoginScreen, MockLoginActionType } from '../mock/MockLoginScreen';
import { MockStorageService } from '../mock/MockStorageService';
import { MockLoginUser, mockUsers, serviceAddressKey } from '../mock/MockUtils';
import { UserActionType } from './UserActionType';
import { UserService } from './UserService';

const log = debug('app:services:user:MockUserService');

type Resolve = () => void;

export type AccountMap = {
  [address: string]: Play.AccountDetails
};

export type ChainBalanceMap = {
  [address: string]: string
};

export const userServiceDataKey = 'userServiceData';
export const loggedInAddressKey = 'loggedInAddress';
export const chainBalanceMapKey = 'chainBalanceMap';

export class MockUserService implements UserService {

  readonly logInPopUp = false;

  private loginElement: HTMLDivElement;
  private userState: UserAccount;
  private userDispatch: React.Dispatch<Action>;
  private initialized = false;
  private initResolves: Resolve[] = [];
  private dispatchResolves: Resolve[] = [];
  private loggedInResolves: Resolve[] = [];
  private accountMap: AccountMap;
  private chainBalanceMap: ChainBalanceMap;

  constructor(
    private mockService: MockStorageService,
    private metadataService: MetadataService
  ) {
    try {
      this.accountMap = mockService.getJson(userServiceDataKey) ?? {};
    } catch (e) {
      this.accountMap = {};
      mockService.putJson(userServiceDataKey, this.accountMap);
    }
    try {
      this.chainBalanceMap = mockService.getJson(chainBalanceMapKey) ?? {};
    } catch (e) {
      this.chainBalanceMap = {};
      mockService.putJson(chainBalanceMapKey, this.chainBalanceMap);
    }
    this.registerServiceAddress();
    this.initLoggedInUser();
  }

  private async initLoggedInUser() {
    await this.waitForInit();
    const loggedInAddress = this.mockService.get(loggedInAddressKey);
    if (!loggedInAddress) { return; }

    const user: MockLoginUser = mockUsers.find(u => u.address === loggedInAddress);
    if (!user) { return; }

    const permissions = [UserPermission.User];
    if (user.service) {
      permissions.push(UserPermission.Admin);
      permissions.push(UserPermission.Service);
    }
    this.mockService.put(loggedInAddressKey, user.address);
    this.userDispatch({
      type: UserActionType.LogIn,
      address: user.address,
      name: user.name,
      permissions
    });
  }

  private registerServiceAddress() {
    const serviceAccount = mockUsers.find(user => user.service);
    this.mockService.put(serviceAddressKey, serviceAccount.address);
  }

  private startLogin() {
    if (this.loginElement) { return; }

    this.loginElement = createLoginScreen(this.loginCallback.bind(this));
    document.body.appendChild(this.loginElement);
  }

  private endLogin() {
    if (!this.loginElement) { return; }

    destroyLoginScreen(this.loginElement);
    document.body.removeChild(this.loginElement);
    this.loginElement = null;
  }

  private loginCallback(loginActionType: MockLoginActionType, data?: any) {
    switch (loginActionType) {
    case MockLoginActionType.Add:
      // not being used
      break;
    case MockLoginActionType.Select:
      const loginUser: MockLoginUser = data;
      const permissions = [UserPermission.User];
      if (loginUser.service) {
        permissions.push(UserPermission.Admin);
        permissions.push(UserPermission.Service);
      }
      this.mockService.put(loggedInAddressKey, loginUser.address);
      this.userDispatch({
        type: UserActionType.LogIn,
        address: loginUser.address,
        name: loginUser.name,
        permissions
      });
      const account = this.getAccountDetails(loginUser.address);
      account.name = loginUser.name;
      this.putAccountDetails(loginUser.address, account);
      this.endLogin();
      break;
    case MockLoginActionType.Close:
      this.endLogin();
      break;
    }
  }

  private checkForInit(): void {
    if (this.initialized) { return; }
    if (!this.userState) { return; }
    if (!this.userDispatch) { return; }

    this.initialized = true;
    this.initResolves.forEach(resolve => resolve());
    this.initResolves = [];
  }

  private checkForLoggedIn(): void {
    if (!this.initialized) { return; }
    if (!this.userState) { return; }
    if (!this.userState.loggedIn) { return; }

    this.loggedInResolves.forEach(resolve => resolve());
    this.loggedInResolves = [];
  }

  private waitForDispatch(): Promise<void> {
    if (this.userDispatch) { return Promise.resolve(); }

    return new Promise(resolve => this.dispatchResolves.push(resolve));
  }

  private waitForLogin(): Promise<void> {
    if (this.userState.loggedIn) { return Promise.resolve(); }

    return new Promise(resolve => this.loggedInResolves.push(resolve));
  }

  private saveChainBalance(): void {
    this.mockService.putJson(chainBalanceMapKey, this.chainBalanceMap);
  }

  getAccountDetails(address: string): Play.AccountDetails {
    if (!this.accountMap[address]) {
      this.accountMap[address] = {
        linked: false,
        balance: null,
        nfts: null,
        packs: null,
        name: null
      };
    }

    return {...this.accountMap[address]};
  }

  putAccountDetails(address: string, details: Play.AccountDetails) {
    this.accountMap[address] = details;
    this.mockService.putJson(userServiceDataKey, this.accountMap);
    if (this.userState?.address !== address) { return; }
    this.userDispatch({ type: UserActionType.UpdateBalance, balance: details.balance });
    this.userDispatch({ type: UserActionType.UpdateNFTs, nfts: details.nfts });
    this.userDispatch({ type: UserActionType.UpdatePacks, packs: details.packs });
    this.userDispatch({ type: UserActionType.UpdateName, name: details.name });
  }

  hasInitialized(): boolean {
    return this.initialized;
  }
  waitForInit(): Promise<void> {
    if (this.initialized) { return Promise.resolve(); }

    return new Promise(resolve => this.initResolves.push(resolve));
  }
  hasDispatch(): boolean {
    return !!this.userDispatch;
  }
  setDispatch(dispatch: Dispatch<Action>): void {
    this.userDispatch = dispatch;
    this.checkForInit();
    if (dispatch) {
      this.dispatchResolves.forEach(resolve => resolve());
      this.dispatchResolves = [];
    }
  }
  setUserState(state: UserAccount): void {
    this.userState = state;
    this.checkForInit();
    this.checkForLoggedIn();
  }
  signUp(): void {
    this.startLogin();
  }
  logIn(): void {
    this.startLogin();
  }
  logOut(): void {
    this.mockService.remove(loggedInAddressKey);
    this.userDispatch({ type: UserActionType.LogOut });
  }
  async checkIfLinked(force?: boolean): Promise<boolean> {
    await this.waitForInit();
    if (!this.userState.loggedIn) { return false; }
    if (!force && this.userState.checked) { return this.userState.linked; }

    const details = this.getAccountDetails(this.userState.address);
    this.userDispatch({ type: UserActionType.UpdateLinked, linked: details.linked });
    this.userDispatch({ type: UserActionType.UpdateBalance, balance: details.balance });
    this.userDispatch({ type: UserActionType.UpdateNFTs, nfts: details.nfts });
    this.userDispatch({ type: UserActionType.UpdatePacks, packs: details.packs });
    this.userDispatch({ type: UserActionType.UpdateChecked, checked: true });
    this.userDispatch({ type: UserActionType.UpdateName, name: details.name });
    return details.linked;
  }
  async maybeLink(): Promise<void> {
    const linked = await this.checkIfLinked();

    if (linked) { return; }

    return this.link();
  }
  async link(): Promise<void> {
    const details = this.getAccountDetails(this.userState.address);
    if (details.balance === null) {
      details.balance = '0.0000000';
    }
    if (details.nfts === null) {
      details.nfts = [];
    }
    if (details.packs === null) {
      details.packs = [];
    }
    details.linked = true;
    this.putAccountDetails(this.userState.address, details);
    const linked = await this.checkIfLinked(true);
    if (!linked) {
      throw new Error('Linking account failed');
    }
  }
  async loadNFTMetadata(): Promise<Play.NFTMetadataMap> {
    const linked = await this.checkIfLinked();
    if (!linked) { return undefined; }
    
    await this.waitForLogin(); // wait for user dispatches to take place

    if (!this.userState.nfts) { return undefined; }

    return this.metadataService.loadNFTMetadata(this.userState.nfts, this.userState.address);
  }
  async loadSingleMetadata(id: number): Promise<Play.NFTMetadata> {
    const linked = await this.checkIfLinked();
    if (!linked) { return undefined; }

    await this.waitForLogin(); // wait for user dispatches to take place

    if (!this.userState.nfts) { return undefined; }
    if (this.userState.nfts.indexOf(id) === -1) { return undefined; }

    return this.metadataService.loadSingleMetadata(id, this.userState.address);
  }
  async loadPublicAccount(address: string): Promise<Play.PublicAccountDetails> {
    const account = this.accountMap[address];
    if (!account) {
      throw new Error('Account not found');
    }

    return {
      nfts: account.nfts,
      packs: account.packs,
      name: account.name
    };
  }
  async getServiceAddress(): Promise<string> {
    return this.mockService.get(serviceAddressKey);
  }

  async depositIntoWallet(balance: string): Promise<boolean> {
    const chainBalance = await this.fetchChainBalance(); // this already checks for login and link
    const platformBalance = this.userState.balance;
    const chainBalanceDec = new Decimal(chainBalance);
    const platformBalanceDec = new Decimal(platformBalance);
    const depositDec = new Decimal(balance);

    if (depositDec.lte('0.0')) {
      throw new Error('Cannot deposit zero or less');
    }
    
    if (depositDec.gt(chainBalanceDec)) {
      throw new Error('Cannot deposit more than available balance')
    }

    const newChainBalanceDec = chainBalanceDec.sub(depositDec);
    const newPlatformBalanceDec = platformBalanceDec.add(depositDec);

    this.setChainBalance(this.userState.address, newChainBalanceDec.toFixed(mockensPlaces));
    const accountDetails = this.getAccountDetails(this.userState.address);
    this.putAccountDetails(this.userState.address, {
      ...accountDetails,
      balance: newPlatformBalanceDec.toFixed(mockensPlaces)
    });

    return true;
  }

  async withdrawFromWallet(balance: string): Promise<boolean> {
    const chainBalance = await this.fetchChainBalance(); // this already checks for login and link
    const platformBalance = this.userState.balance;
    const chainBalanceDec = new Decimal(chainBalance);
    const platformBalanceDec = new Decimal(platformBalance);
    const withdrawDec = new Decimal(balance);

    if (withdrawDec.lte('0.0')) {
      throw new Error('Cannot withdraw zero or less');
    }

    if (withdrawDec.gt(platformBalance)) {
      throw new Error('Cannot withdraw more than available balance');
    }

    const newPlatformBalanceDec = platformBalanceDec.sub(withdrawDec);
    const newChainBalanceDec = chainBalanceDec.add(withdrawDec);

    this.setChainBalance(this.userState.address, newChainBalanceDec.toFixed(mockensPlaces));
    const accountDetails = this.getAccountDetails(this.userState.address);
    this.putAccountDetails(this.userState.address, {
      ...accountDetails,
      balance: newPlatformBalanceDec.toFixed(mockensPlaces)
    });

    return true;
  }
  async fetchChainBalance(): Promise<string> {
    const linked = await this.checkIfLinked();
    if (!linked) {
      throw new Error('Not linked');
    }

    await this.waitForLogin(); // wait for user dispatches to take place

    const chainBalance = this.chainBalanceMap[this.userState.address] ?? '0.0';

    if (this.userState.chainBalance !== chainBalance) {
      this.userDispatch({
        type: UserActionType.UpdateChainBalance,
        chainBalance
      });
    }

    return chainBalance;
  }

  setChainBalance(address: string, balance: string): void {
    this.chainBalanceMap[address] = balance;
    this.saveChainBalance();
    if (address === this.userState?.address) {
      this.userDispatch({
        type: UserActionType.UpdateChainBalance,
        chainBalance: balance
      });
    }
  }

  getChainBalance(address: string, balance: string): string {
    return this.chainBalanceMap[address] ?? '0.0';
  }
}
