import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { Pack } from '../../services/packs/PacksService';

import './SinglePackElement.scss';

export type SinglePackProps = {
  pack: Pack,
  count: number,
  baseUrl?: string
};

export function SinglePackElement({ pack, count, baseUrl }: SinglePackProps): JSX.Element {
  const navigate = useNavigate();

  const children =
    <div className="card-body">
      <h5 className="card-title">{pack.name}</h5>
      <div className="card-img-background">
        { pack.imgSrc && (
          <img src={pack.imgSrc} className="card-img-top" alt={pack.name} />
        )}
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <p className="card-text mx-1">
            {count} &nbsp;<small>{pack.rarity} {count > 1 ? 'packs' : 'pack'}</small>
          </p>
        </div>

        <div>
          {baseUrl && <button className="btn btn-primary">Open</button>}
        </div>
      </div>
    </div>

  const className = `card single-pack ${pack.rarity.toLowerCase()}`;

  return ( baseUrl ?
    <Link to={ baseUrl + pack.id + '/reveal' } className={ className }>
      { children }
    </Link> :
    <div className={ className }>
      { children }
    </div>
  );
}
