import { MouseEvent, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Alert } from '../../components/alerts/Alert';
import { useUserState } from '../../services/user/UserContext';
import { useUserService } from '../../services/user/UserServiceContext';
import { Page } from '../Page';

import './LoginPage.scss';

export function LoginPage() {
  const userState = useUserState();
  const userService = useUserService();
  const navigate = useNavigate();
  const location = useLocation();

  function logIn(e?: MouseEvent<HTMLAnchorElement>) {
    if (e) {
      e.preventDefault();
    }
    userService.logIn();
  }

  useEffect(() => {
    if (userState.loggedIn) {
      const searchParams = new URLSearchParams(location.search);
      if (searchParams.has('r')) {
        navigate(searchParams.get('r'));
      } else {
        navigate('/');
      }
    }
  }, [userState]);

  useEffect(() => {
    // prevent login attempt if a window pop up is required, which are blocked
    // when not called via a mouse event.
    if (!userService.logInPopUp) {
      logIn();
    }
  }, []);

  return (
    <Page title="Login" className="login container py-2">
      <Alert.Warning>This page requires you to be logged in. Please <a href="#" onClick={logIn}>click here</a> to log in.</Alert.Warning>
    </Page>
  );
}
