import React from 'react';
import debug from 'debug';

import { Navigate, useParams } from 'react-router';
import { getMarketplace } from '../../services/ServiceFactory';
import { Page } from '../Page';
import { Alert } from '../../components/alerts/Alert';

import { TransferModal } from '../../components/transfer-modal/TransferModal';
import { UserAccount } from '../../common/UserAccount';
import { useUserState } from '../../services/user/UserContext';
import { SaleState } from '../../common/SaleState';
import warnIcon from '../../assets/svg/connect-warn.svg';
import ethIcon from '../../assets/svg/eth-price.svg';
import { Moment } from '../../components/moment/Moment';

import './MarketplaceSinglePage.scss';

const log = debug('app:pages:marketplace:MarketplaceSinglePage');

type StoreSinglePageProps = {
  nftIdStr: string,
  userState: UserAccount
};

type StoreSinglePageState = {
  nftId: number,
  nftMetadata: Play.NFTMetadata,
  pageLoading: boolean,
  buying: boolean,
  saleState: SaleState,
  redirectTo: string,
  showDepositModal: boolean,
  error: string
};

function SaleStateStatus(
  { saleState, className, openDepositModal }: {
    saleState: SaleState,
    className: string,
    openDepositModal: (e?: React.MouseEvent<any>) => void
  }
): JSX.Element {
  
  switch (saleState) {
  case SaleState.LogInRequired:
    return <span className={className}>Wallet needs to be connected to buy.</span>;
  case SaleState.LinkRequired:
    return <span className={className}>Wallet needs to be linked to buy.</span>;
  case SaleState.OutOfStock:
    return <span className={className}>Out of stock.</span>;
  case SaleState.Unaffordable:
    return <span className={className}>Not enough balance buy. <a href="#" onClick={openDepositModal}>Deposit ETH</a> to be able to buy.</span>;
  case SaleState.Available:
    return null;
  default:
    return <span className={className}>Unexpected pack state.</span>;
  }
}

class InternalMarketplaceSinglePage extends React.Component<StoreSinglePageProps, StoreSinglePageState> {
  private marketplace = getMarketplace();
  constructor(props) {
    super(props);

    this.state = {
      nftId: undefined,
      nftMetadata: undefined,
      pageLoading: true,
      buying: false,
      saleState: undefined,
      redirectTo: undefined,
      showDepositModal: false,
      error: null
    };

    this.buy = this.buy.bind(this);
    this.openDepositModal = this.openDepositModal.bind(this);
    this.closeDepositModal = this.closeDepositModal.bind(this);
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps: Readonly<StoreSinglePageProps>, prevState: Readonly<StoreSinglePageState>) {
    if (prevProps.userState.balance !== this.props.userState.balance ||
      prevState.nftId !== this.state.nftId
    ) {
      this.updateSaleState();
    }
  }

  private async init() {
    let nftId: number = undefined;
    try {
      nftId = parseInt(this.props.nftIdStr);
      this.setState({ nftId });
    } catch (e) {
      // render will take care of showing the right error for this
      this.setState({ pageLoading: false });
    }

    if (nftId === undefined) { return; }

    try {
      await this.load(nftId);
    } catch (e) {
      this.setState({ error: e.message });
    }
    this.setState({ pageLoading: false });
  }

  private async load(nftId: number) {
    try {
      const nftMetadata = await this.marketplace.loadSingleSaleMetadata(nftId);
      this.setState({ nftMetadata });
    } catch (e) {
      log(e);
      throw new Error('Error occurred while loading metadata');
    }
  }

  private async updateSaleState() {
    if (!this.state.nftId) { return; }

    const saleState = await this.marketplace.canPurchase(this.state.nftId);
    this.setState({ saleState });
  }

  private async buy() {
    if (!this.state.nftId) { return; }

    this.setState({ buying: true });
    try {
      await this.marketplace.purchase(this.state.nftId);
      this.setState({ redirectTo: '/profile' });
    } catch (e) {
      log(e);
      this.setState({ error: 'Error occurred while buying' });
    }
    this.setState({ buying: false });
  }

  private openDepositModal(e?: React.MouseEvent<any>) {
    if (e) {
      e.preventDefault();
    }

    this.setState({ showDepositModal: true });
  }

  private closeDepositModal() {
    this.setState({ showDepositModal: false });
  }

  render() {
    return (
      <Page className="store-single-page container py-2">
        {this.state.redirectTo && (
          <Navigate to={this.state.redirectTo} />
        )}
        {this.state.pageLoading ? (
          <Alert.Info>
            Loading...
          </Alert.Info>
        ) : this.state.nftId === undefined ? (
          <Alert.Danger>
            Invalid NFT ID format.
          </Alert.Danger>
        ) : (
          <>
            <div className="mb-2">
              <button className="btn btn-success me-2" onClick={this.buy} disabled={this.state.buying || this.state.saleState !== SaleState.Available}>
                Buy
              </button>
              <SaleStateStatus className="me-2"
                saleState={this.state.saleState}
                openDepositModal={this.openDepositModal}
              />
              {this.state.buying && (
                <span className="me-2">Buying...</span>
              )}
              {this.state.error && (
                <span className="me-2">{this.state.error}</span>
              )}
            </div>
            {!this.state.nftMetadata ? (
              <Alert.Warning>
                No metadata.
              </Alert.Warning>
            ) : (
              <>
                <Moment metadata={this.state.nftMetadata} backText="Back to Marketplace" backURL="/marketplace">
                  <div className="marketplace-single-moment-price d-flex flex-row justify-content-start">
                    <img src={ethIcon} />
                    <div className="price-value">{ this.state.nftMetadata.price }</div>
                  </div>
                  <div className="marketplace-single-moment-royalty">
                    Includes 6.99% royalty
                  </div>
                  <div>
                    <button className="btn btn-primary marketplace-single-moment-buy disabled">BUY NOW</button>
                  </div>
                  <div className="marketplace-single-moment-connect d-flex justify-content-center">
                    <img src={warnIcon} />
                    <div>You must Connect to <a href="/login">Immutable X</a> with your wallet to buy</div>
                  </div>
                </Moment>
              </>
            )}
            <TransferModal show={this.state.showDepositModal} deposit onDismiss={this.closeDepositModal} />
          </>
        )}
      </Page>
    );
  }
}

export function MarketplaceSinglePage() {
  const userState = useUserState();
  const { nftId } = useParams();

  return (
    <InternalMarketplaceSinglePage
      userState={userState}
      nftIdStr={nftId}
    />
  );
}
