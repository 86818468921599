import React from 'react';
import debug from 'debug';

import { NavigateFunction, useNavigate, useParams } from 'react-router';
import { Alert } from '../../components/alerts/Alert';
import { Pack, PacksService } from '../../services/packs/PacksService';
import { getPacksService } from '../../services/ServiceFactory';
import { Page } from '../Page';
import { SinglePackElement } from '../../components/packs/SinglePackElement';
import { useUserState } from '../../services/user/UserContext';
import { UserAccount } from '../../common/UserAccount';

import './MySinglePackPage.scss';

const log = debug('app:pages:profile:MySinglePackPage');

type PageProps = {
  packIdStr: string,
  packsService: PacksService,
  userState: UserAccount,
  navigate: NavigateFunction
};

type PageState = {
  packId: number,
  pack: Pack,
  loading: boolean,
  owner: boolean,
  error: string
};

class _MySinglePackPage extends React.Component<PageProps, PageState> {
  constructor(props) {
    super(props);

    this.state = {
      packId: undefined,
      pack: undefined,
      loading: true,
      owner: false,
      error: undefined
    };

    this.openPack = this.openPack.bind(this);
  }

  componentDidMount() {
    this.load();
  }

  private async load() {
    // parse ID string
    let packId;
    try {
      packId = parseInt(this.props.packIdStr);
    } catch (e) {
      const msg = 'Invalid pack ID format';
      log('error loading pack:', msg, e);
      this.setState({ loading: false, error: msg });
      return;
    }
    this.setState({ packId });

    // load pack details
    const pack = await this.props.packsService.getSinglePack(packId);

    if (!pack) {
      const msg = 'Pack not found';
      log('error loading pack:', msg);
      this.setState({ loading: false, error: msg });
      return;
    }

    // is current user owner of pack
    const owner = this.props.userState.packs &&
      this.props.userState.packs.indexOf(packId) !== -1;

    this.setState({ loading: false, pack, owner });
  }

  private openPack() {
    if (!this.state.owner) { return; }
    this.props.navigate(`/profile/me/packs/${this.state.packId}/reveal`);
  }

  render() {
    return (
      <Page className="my-packs-page container py-2">
        {this.state.loading ? (
          <Alert.Info>Loading...</Alert.Info>
        ) : (
          <>
            {this.state.error && (
              <Alert.Danger>{this.state.error}</Alert.Danger>
            )}
            {!this.state.pack ? (
              <Alert.Warning>No pack data.</Alert.Warning>
            ) : (
              <SinglePackElement pack={this.state.pack} count={1} />
            )}
            {this.state.owner && !this.state.pack.opened && (
              <div>
                <button className="btn btn-success" onClick={this.openPack}>Open Pack</button>
              </div>
            )}
          </>
        )}
      </Page>
    );
  }
}

export function MySinglePackPage() {
  const { packId } = useParams();
  const packsService = getPacksService();
  const userState = useUserState();
  const navigate = useNavigate();

  return <_MySinglePackPage
    packIdStr={packId}
    packsService={packsService}
    userState={userState}
    navigate={navigate}
  />
}
