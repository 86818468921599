import React from 'react';
import { UserAccount } from '../../common/UserAccount';
import { useUserState } from '../../services/user/UserContext';
import { UserPermission } from '../../common/UserPermission';
import { UserService } from '../../services/user/UserService';
import { useUserService } from '../../services/user/UserServiceContext';
import { NFTCardSize } from '../../components/nft-card/NFTCard';
import { NFTCardList } from '../../components/nft-card/NFTCardList';
import { getMetadataService } from '../../services/ServiceFactory';
import { MetadataService } from '../../services/metadata/MetadataService';

import { FilterHeader } from '../../components/filters/FilterHeader';

import { MomentsFilter, Filters, MomentsFiltersState } from '../../components/filters/MomentsFilter';

export type MyMomentsSectionProps = {
  profileId: string
};

type PageProps = {
  profileId: string,
  userService: UserService,
  userState: UserAccount,
  metadataService: MetadataService
};

type PageState = MomentsFiltersState & {
  checking: boolean,
  loadingMetadata: boolean,
  nftItems: Play.NFTMetadata[],
  showFilters: boolean
};

const permissions = [UserPermission.User];

class _MyMomentsPage extends React.Component<PageProps, PageState> {
  private publicDetails: Play.PublicAccountDetails;

  private filterSetters = Filters.map((_, index) =>
    (value: string) => this.setState({...this.state,
      valuesFilters: this.state.valuesFilters.map((prev, i) => i === index ? value : prev) }));
  private expandedSetters = Filters.map((_, index) =>
    (value: boolean) => this.setState({...this.state,
      expandedFilters: this.state.expandedFilters.map((prev, i) => i === index ? value : prev) }));

  constructor(props: PageProps) {
    super(props);

    this.state = {
      checking: true,
      loadingMetadata: false,
      nftItems: [],
      showFilters: false,
      expandedFilters: Filters.map(_ => false),
      valuesFilters: Filters.map(_ => '')
    };

    this.setShowFilters = this.setShowFilters.bind(this);
  }

  componentDidMount() {
    this.load();
  }


  private async load() {
    this.setState({ checking: true });
    const profileId = this.props.profileId === 'me' ?
      this.props.userState.address
      : this.props.profileId;
    const account = await this.props.userService.loadPublicAccount(profileId);
    this.setState({ checking: false });
    if (!account) { return; }

    if (!account.nfts) { return; }

    this.setState({ loadingMetadata: true });
    await this.loadMetadata(profileId, account);
    this.setState({ loadingMetadata: false });
  }

  private async loadMetadata(address: string, account: Play.PublicAccountDetails) {
    const metadataMap = await this.props.metadataService.loadNFTMetadata(account.nfts, address);
    const nftItems = Object.values(metadataMap).filter(metadata => !!metadata);
    this.setState({ nftItems });
  }

  private setShowFilters() {
    this.setState({...this.state, showFilters: !this.state.showFilters});
  }

  render() {
    const nftItems = this.state.nftItems;
    return (
      <div className="my-moments py-2">
        <FilterHeader>
          <div>
            <button onClick={ this.setShowFilters }
              className={ `btn btn-primary btn-filter${this.state.showFilters ? ' active' : ''}` }/>

            <button className="btn btn-primary btn-sort">
              <span><b>Newest Received</b></span>
            </button>
          </div>

          <div>
            <p> { nftItems ? nftItems.length : 0} <small> Moments total</small></p>
          </div>
        </FilterHeader>

        <div className="d-flex">
          { this.state.showFilters &&
            <div className="moments-filters-container">
              { Filters.map((filter, index) => <MomentsFilter
                key={ filter }
                label={ filter }
                expanded={ this.state.expandedFilters[index] }
                setExpanded={ this.expandedSetters[index] }
                value = { this.state.valuesFilters[index] }
                setValue = { this.filterSetters[index] }
              /> ) }
            </div>
          }

          {this.state.checking ? (
            <div className="alert alert-info">Loading account...</div>
          ) : this.state.loadingMetadata ? (
            <div className="alert alert-info">Loading metadata...</div>
          ) : (
            <NFTCardList nftItems={this.state.nftItems} cardSize={NFTCardSize.Small} baseUrl={`/profile/${this.props.profileId}/moments/`} />
          )}
        </div>
      </div>
    );
  }
}

export function MyMomentsSection(props: MyMomentsSectionProps) {
  const userState = useUserState();
  const userService = useUserService();
  const metadataService = getMetadataService();

  return (
    <_MyMomentsPage
      profileId={props.profileId}
      userState={userState}
      userService={userService}
      metadataService={metadataService}
    />
  );
}
