import React, { FunctionComponent } from 'react';
import { getMarketplace } from '../../services/ServiceFactory';
import { NFTCardSize } from '../../components/nft-card/NFTCard';
import { NFTCardList } from '../../components/nft-card/NFTCardList';
import { Page } from '../Page';
import { MomentsFilter, Filters, MomentsFiltersState } from '../../components/filters/MomentsFilter';

import './MarketplacePage.scss';
import { Alert } from '../../components/alerts/Alert';

import iconSortDown from '../../assets/svg/sort-down.svg';
import iconSortUp from '../../assets/svg/sort-up.svg';
import iconZoom from '../../assets/svg/zoom.svg';
import { FilterHeader } from '../../components/filters/FilterHeader';
import { InventoryHeader } from '../../components/inventory-header/InventoryHeader';

const DateAsc = 'date_ascending';
const DateDesc = 'date_descending';
type DateSortType = typeof DateAsc | typeof DateDesc;

const DefaultSales = 'default';
const TopSales = 'top';
const LatestSales = 'latest';
type SalesListType = typeof DefaultSales | typeof TopSales | typeof LatestSales;

type StoreState = MomentsFiltersState & {
  nfts: Play.NFTMetadata[],
  loading: boolean,
  salesList: SalesListType,
  dateList: DateSortType,
  textFilter: string
}

class InternalMarketplacePage extends React.Component<{}, StoreState> {
  private marketplace = getMarketplace();
  private filterSetters = Filters.map((_, index) =>
    (value: string) => this.setState({...this.state,
      valuesFilters: this.state.valuesFilters.map((prev, i) => i === index ? value : prev) }));
  private expandedSetters = Filters.map((_, index) =>
    (value: boolean) => this.setState({...this.state,
      expandedFilters: this.state.expandedFilters.map((prev, i) => i === index ? value : prev) }));

  constructor(props) {
    super(props);
    this.state = {
      nfts: [],
      loading: true,
      salesList: DefaultSales,
      dateList: DateDesc,
      textFilter: '',
      showFilters: false,
      expandedFilters: Filters.map(_ => false),
      valuesFilters: Filters.map(_ => '')
    };
  }
  componentDidMount() {
    this.load();
  }
  private async load() {
    this.setState({...this.state, nfts: [], loading: true });

    const loadedNfts = await this.marketplace.loadSales();

    this.setState({...this.state, nfts: loadedNfts, loading: false });
  }
  private contains(text: string|undefined, filter: string) {
    return text && text.toLowerCase().includes(filter) || false;
  }
  render() {
    const textFilter = this.state.textFilter.toLowerCase();

    const nftItems = !textFilter ? this.state.nfts :
      this.state.nfts.filter(e => this.contains(e.name, textFilter) ||
        this.contains(e.packId, textFilter) || this.contains(e.rarity, textFilter) )

    const dateDesc = this.state.dateList === DateDesc;

    return (
      <Page title="Marketplace" className="marketplace container py-2">
        <InventoryHeader title="MARKETPLACE">
          <button className={ `btn btn-secondary${this.state.salesList === DefaultSales ? ' current' : ''}` }
            onClick={ () => this.setState({...this.state, salesList: DefaultSales})}>
            FOR SALE
          </button>
          <button className={ `btn btn-secondary${this.state.salesList === LatestSales ? ' current' : ''}` }
            onClick={ () => this.setState({...this.state, salesList: LatestSales})}>
            LATEST SALES
          </button>
          <button className={ `btn btn-secondary${this.state.salesList === TopSales ? ' current' : ''}` }
            onClick={ () => this.setState({...this.state, salesList: TopSales})}>
            TOP SALES
          </button>
        </InventoryHeader>

        <FilterHeader>
          <div className="d-flex justify-content-start">
            <div>
              <button className={ `btn btn-primary btn-filter${this.state.showFilters ? ' active' : ''}` }
                onClick={ () => this.setState({...this.state, showFilters: !this.state.showFilters })}>
              </button>
            </div>
            <div className="position-relative input-filter">
              <img className="search-input-zoom" src={iconZoom}></img>
              <input className="form-control" type="text" placeholder="Enter a Keyword"
                value={ this.state.textFilter }
                onChange={ e => this.setState({...this.state, textFilter: e.target.value })}/>
            </div>
          </div>
          <div>
            <button className="btn btn-primary btn-sort" onClick={
              () => this.setState({...this.state,
                dateList: dateDesc ? DateAsc : DateDesc })
            } >
              <span>
                <b>Date Listed</b>{' '}
                { dateDesc ? '(Newest Listed First)' : '(Oldest Listed First)' }
              </span>
            </button>
          </div>
        </FilterHeader>

        {this.state.loading ? (
          <Alert.Info>
            Loading...
          </Alert.Info>
        ) : (
          <div className="marketplace-items-container d-flex">
            { this.state.showFilters &&
              <div className="moments-filters-container">
                { Filters.map((filter, index) => <MomentsFilter
                  key={ filter }
                  label={ filter }
                  expanded={ this.state.expandedFilters[index] }
                  setExpanded={ this.expandedSetters[index] }
                  value = { this.state.valuesFilters[index] }
                  setValue = { this.filterSetters[index] }
                /> ) }
              </div>
            }
            <NFTCardList nftItems={nftItems} cardSize={NFTCardSize.Small} baseUrl="/marketplace/" showPrice />
          </div>
        )}
      </Page>
    );
  }
}

export function MarketplacePage() {
  return (
    <InternalMarketplacePage />
  );
}
