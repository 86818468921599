import React from 'react';
import debug from 'debug';

import { Decimal } from 'decimal.js';
import { UserAccount } from '../../common/UserAccount';
import { hasPermission, UserPermission } from '../../common/UserPermission';
import { Action } from '../../context/GenerateContext';
import { MetadataService } from '../metadata/MetadataService';
import { MockStorageService } from '../mock/MockStorageService';
import { UserService } from '../user/UserService';
import { AdminService, ProtoMetadata } from './AdminService';
import { PackSale, PacksService, Rarity } from '../packs/PacksService';
import { mockensPlaces } from '../../consts';

const log = debug('app:services:admin:MockAdminService');

const adminServiceKey = 'adminService';
const nftIdPoolKey = 'nftIdPool';

export class MockAdminService implements AdminService {
  private details: Play.ServiceAccountDetails;
  private userState: UserAccount;
  private userDispatch: React.Dispatch<Action>;
  private nftIdPool: number;

  constructor(
    private mockStorageService: MockStorageService,
    private metadataService: MetadataService,
    private userService: UserService,
    private packsService: PacksService
  ) {
    // account details
    try {
      this.details = mockStorageService.getJson(adminServiceKey) ?? {};
    } catch (e) {
      this.details = {};
      mockStorageService.putJson(adminServiceKey, this.details);
    }

    // nft id pool
    try {
      const nftIdPoolStr = mockStorageService.get(nftIdPoolKey);
      if (nftIdPoolStr) {
        this.nftIdPool = parseInt(nftIdPoolStr);
      } else {
        this.nftIdPool = 1;
      }
    } catch (e) {
      this.nftIdPool = 1;
      this.saveNftIdPool();
    }
  }
  private saveNftIdPool() {
    this.mockStorageService.put(nftIdPoolKey, this.nftIdPool.toString());
  }
  private saveDetails() {
    this.mockStorageService.putJson(adminServiceKey, this.details);
  }
  setUserDispatch(dispatch: React.Dispatch<Action>): void {
    this.userDispatch = dispatch;
  }
  setUserState(state: UserAccount): void {
    this.userState = state;
  }
  async linkService(): Promise<void> {
    if (!hasPermission(this.userState.permissions, UserPermission.Service)) { return; }

    if (!this.details.sale) {
      this.details.sale = [];
    }
    this.details.saleInitted = true;
    if (!this.details.balance) {
      this.details.balance = this.userState.balance || '0.000000';
    }
    if (!this.details.nfts) {
      this.details.nfts = this.userState.nfts || [];
    }

    // this would also link the non-service account data, since they're one and the same
    await this.userService.link();
    this.saveDetails();
  }
  async loadServiceSetup(): Promise<Play.ServiceAccountDetails> {
    if (!hasPermission(this.userState.permissions, UserPermission.Service)) { return undefined; }

    return {...this.details};
  }
  async mintNFT(name: string, rarity: string, uri: string, packId?: string): Promise<number> {
    const linked = await this.userService.checkIfLinked(true);
    if (!linked) {
      throw new Error('Account not linked');
    }
    if (!hasPermission(this.userState.permissions, UserPermission.Service)) {
      throw new Error('Account does not have permission');
    }

    const metadata: Play.NFTMetadata = {
      id: this.nftIdPool++,
      name, rarity, uri
    };
    if (packId !== undefined) {
      metadata.packId = packId;
    }
    this.saveNftIdPool();

    const nfts = [...this.details.nfts, metadata.id];
    const accountDetails = this.userService.getAccountDetails(this.userState.address);
    this.userService.putAccountDetails(this.userState.address, {
      ...accountDetails,
      nfts
    });
    this.metadataService.putMetadatas?.([metadata]);
    this.details.nfts = nfts;
    this.saveDetails();
    return metadata.id;
  }
  async mintMultiNFT(metadatas: ProtoMetadata[]): Promise<number[]> {
    const linked = await this.userService.checkIfLinked(true);
    if (!linked) {
      throw new Error('Account not linked');
    }
    if (!hasPermission(this.userState.permissions, UserPermission.Service)) {
      throw new Error('Account does not have permission');
    }

    const newMetadatas = metadatas.map(({ name, rarity, uri })=> ({
      id: this.nftIdPool++,
      name, rarity, uri
    }));
    this.saveNftIdPool();

    const accountDetails = this.userService.getAccountDetails(this.userState.address);
    const nfts = [...this.details.nfts, ...newMetadatas.map(({ id }) => id)];
    this.userService.putAccountDetails(this.userState.address, {
      ...accountDetails,
      nfts
    });
    this.metadataService.putMetadatas?.(newMetadatas);
    this.details.nfts = nfts;
    this.saveDetails();

    return newMetadatas.map(metadata => metadata.id);
  }
  async mintPlayTokens(receiverAddress: string, amount: string): Promise<void> {
    const linked = await this.userService.checkIfLinked(true);
    if (!linked) {
      throw new Error('Account not linked');
    }
    if (!hasPermission(this.userState.permissions, UserPermission.Service)) {
      throw new Error('Account does not have permission');
    }
    
    const accountDetails = this.userService.getAccountDetails(receiverAddress);
    if (!accountDetails.linked) {
      const errorMsg = `${receiverAddress} not linked`;
      log(`MockAdminService.mintPlayTokens(): ${errorMsg}`);
      throw new Error(errorMsg);
    }

    const amountDec = new Decimal(amount);
    const balanceDec = new Decimal(accountDetails.balance);
    const newBalanceDec = balanceDec.add(amountDec);
    const newBalance = newBalanceDec.toFixed(mockensPlaces);

    accountDetails.balance = newBalance;
    this.userService.putAccountDetails(receiverAddress, accountDetails);
    if (receiverAddress === this.userState.address) {
      this.details.balance = newBalance;
      this.saveDetails();
    }
  }
  async createPackSale(
    name: string, imgSrc: string, rarity: Rarity,
    available: boolean, availableAt: number,
    price: string,
    metadataSets: ProtoMetadata[][]
  ): Promise<PackSale> {
    // flatten metadataSets to mint multiple NFTs in one go
    // eslint-disable-next-line arrow-body-style
    const flatProtoMetadatas = metadataSets.reduce((accumulator, metadatas) => {
      return accumulator.concat(metadatas);
    }, []);
    const nftIds = await this.mintMultiNFT(flatProtoMetadatas);

    // re-inflate into nftSets
    const nftSets: number[][] = [];
    metadataSets.forEach(metadatas => {
      const nftSingleSet: number[] = [];
      metadatas.forEach(() => {
        nftSingleSet.push(nftIds.shift());
      });
      nftSets.push(nftSingleSet);
    });

    // create packs
    const packIds: number[] = [];
    const createPackPromises = nftSets.map(singleSet => this.packsService.createPack(name, imgSrc, rarity, singleSet));
    const packs = await Promise.all(createPackPromises);
    packs.forEach(pack => packIds.push(pack.id));

    // create sale
    const packSale: PackSale = {
      saleId: -1, // will be replaced
      name, imgSrc, rarity,
      available, availableAt,
      price, packIds
    };

    // add the sale
    const saleIds = this.packsService.addSales([packSale]);

    // add to featured
    saleIds.forEach(saleId => this.packsService.addFeatured(saleId));

    return packSale;
  }

  clearMockData(): void {
    this.mockStorageService.clear();
    this.mockStorageService.setAllowMockData(false);
  }

  allowMockData(): void {
    this.mockStorageService.clear();
    this.mockStorageService.setAllowMockData(true);
  }

  exportMockData(): string {
    const data = this.mockStorageService.exportData();
    return JSON.stringify(data, undefined, 2);
  }

  async addToChainBalance(receiverAddress: string, amount: string): Promise<void> {
    const chainBalance = this.userService.getChainBalance(receiverAddress, amount);
    const chainBalanceDec = new Decimal(chainBalance);
    const amountDec = new Decimal(amount);

    const newChainBalanceDec = chainBalanceDec.add(amountDec);
    this.userService.setChainBalance(receiverAddress, newChainBalanceDec.toFixed(mockensPlaces));
  }
}
