import React from 'react';
import { SaleState } from '../../common/SaleState';
import { UserAccount } from '../../common/UserAccount';
import { Action } from '../../context/GenerateContext';
import { Pack, PackSale, PacksService } from './PacksService';

export class RestPacksService implements PacksService {
  setUserDispatch(dispatch: React.Dispatch<Action>): void {
    throw new Error('Method not implemented.');
  }
  setUserState(state: UserAccount): void {
    throw new Error('Method not implemented.');
  }
  getFeaturedSales(): Promise<PackSale[]> {
    throw new Error('Method not implemented.');
  }
  getMidPageSales(): Promise<PackSale[]> {
    throw new Error('Method not implemented.');
  }
  getSales(): Promise<PackSale[]> {
    throw new Error('Method not implemented.');
  }
  getSingleSale(): Promise<PackSale> {
    throw new Error('Method not implemented.');
  }
  getPacks(packIds: number[]): Promise<Pack[]> {
    throw new Error('Method not implemented.');
  }
  getSinglePack(packId: number): Promise<Pack> {
    throw new Error('Method not implemented.');
  }
  buyPack(saleId: number): Promise<number> {
    throw new Error('Method not implemented.');
  }
  openPack(id: number): Promise<number[]> {
    throw new Error('Method not implemented.');
  }
  canBuyPack(saleId: number): Promise<SaleState> {
    throw new Error('Method not implemented.');
  }
}
