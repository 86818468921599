import { FclService } from '../fcl/FclService';
import { MetadataService } from './MetadataService';

export class FclMetadataService implements MetadataService {
  constructor(
    private fclService: FclService
  ) { }
  async loadNFTMetadata(nftIds: number[], address: string): Promise<Play.NFTMetadataMap> {
    return this.fclService.loadMetadata(nftIds, address);
  }
  async loadSingleMetadata(nftId: number, address: string): Promise<Play.NFTMetadata> {
    const map = await this.fclService.loadMetadata([nftId], address);
    return map[nftId];
  }
}
