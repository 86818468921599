import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router';
import { useUserState } from '../../services/user/UserContext';
import { useUserService } from '../../services/user/UserServiceContext';
import { Page } from '../Page';

import './LoginPage';

export function LogoutPage() {
  const userState = useUserState();
  const userService = useUserService();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userState.loggedIn) {
      navigate('/');
    } else {
      userService.logOut();
    }
  }, [userState]);

  return (
    <Page title="Log Out" className="logout container py-2">
      <p>Logging out...</p>
    </Page>
  );
}
