import { useState } from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from '../nav-link/NavLink';
import { LoginStatus } from '../login-status/LoginStatus';
import './NavBar.scss';
import { useUserState } from '../../services/user/UserContext';
import { UserAccount } from '../../common/UserAccount';
import { UserPermission } from '../../common/UserPermission';
import swayLogo from '../../assets/sway-logo.png';

export function NavBar() {
  return (
    <div className="text-center swl-nav-bar">
      <Link className="navbar-brand py-0 ms-2" to="/">
        <img className="sway-logo" src={swayLogo} alt="Sway Stories" />
      </Link>
      <NavLink className="nav-link"
        aria-current="page"
        to="/packs"
        activeClassName="active"
      >Packs</NavLink>
      <NavLink className="nav-link"
        aria-current="page"
        to="/profile"
        activeClassName="active"
      >Collections</NavLink>
      <NavLink className="nav-link"
        aria-current="page"
        to="/marketplace"
        activeClassName="active"
      >Marketplace</NavLink>
      <NavLink className="nav-link"
        aria-current="page"
        to="/community"
        activeClassName="active"
      >Community</NavLink>
      <NavLink className="nav-link"
        aria-current="page"
        to="/help"
        activeClassName="active"
      >Help</NavLink>
      <LoginStatus />
    </div>
  );
}
