import React from 'react';

import './InventoryHeader.scss';

export const InventoryHeader = ({title, children}: {title: string, children: React.ReactNode}) =>
  <div className="inventory-header d-flex flex-column justify-content-between">
    <div className="inventory-header-top d-flex align-items-center">
      <div className="inventory-header-title">{ title }</div>
    </div>

    <div className="inventory-header-bottom d-flex align-items-center">
      { children }
    </div>
  </div>
