import React, { useEffect } from 'react';
import { getAdminService, getMarketplace, getPacksService, getUserService } from '../ServiceFactory';

import { UserDispatchContext, UserStateContext } from './UserContext';
import { UserService } from './UserService';

const UserServiceContext = React.createContext<UserService>(undefined);

function UserServiceProvider({ children }) {
  const userService = getUserService();
  const adminService = getAdminService();
  const marketplace = getMarketplace();
  const packsService = getPacksService();

  const userState = React.useContext(UserStateContext);
  const userDispatch = React.useContext(UserDispatchContext);

  if (userState === undefined) {
    throw new Error('UserServiceProvider must be used within a UserContextProvider');
  }
  if (userDispatch === undefined) {
    throw new Error('UserServiceProvider must be used within a UserContextProvider');
  }

  useEffect(() => {
    userService.setUserState(userState);
    adminService.setUserState(userState);
    marketplace.setUserState(userState);
    packsService.setUserState(userState);
  }, [userState]);
  useEffect(() => {
    userService.setDispatch(userDispatch);
    adminService.setUserDispatch(userDispatch);
    marketplace.setUserDispatch(userDispatch);
    packsService.setUserDispatch(userDispatch);
  }, [userDispatch]);

  return (
    <UserServiceContext.Provider value={userService}>
      {children}
    </UserServiceContext.Provider>
  );
}

function useUserService() {
  const context = React.useContext(UserServiceContext);
  if (context === undefined) {
    throw new Error('useUserService must be used within a UserServiceProvider');
  }
  return context;
}

function UserServiceConsumer({ children }) {
  return (
    <UserServiceContext.Consumer>
      { userService => {
        if (userService === undefined) {
          throw new Error('UserServiceConsumer must be used within a UserServiceProvider');
        }
        return children([userService]);
      }}
    </UserServiceContext.Consumer>
  )
}

function withUserService(Component) {
  return props => (
    <UserServiceConsumer>
      {([userService]) => (
        <Component
          userService={userService}
          {...props} />
      )}
    </UserServiceConsumer>
  )
}

export {
  UserServiceContext,
  UserServiceProvider,
  UserServiceConsumer,
  useUserService,
  withUserService
}
