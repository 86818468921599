import { SaleState } from '../../common/SaleState';
import { FclService } from '../fcl/FclService';
import { Marketplace } from './Marketplace';

export class FclMarketplace implements Marketplace {
  constructor(
    private fclService: FclService
  ) {}

  setUserState(): void { } // do nothing
  setUserDispatch(): void { } // do nothing

  async loadSales(): Promise<Play.NFTMetadata[]> {
    return this.fclService.loadSales();
  }
  async loadSingleSaleMetadata(nftId: number): Promise<Play.NFTMetadata> {
    const metadatas = await this.fclService.loadSaleMetadata([nftId]);
    return metadatas[nftId];
  }
  async addToMarket(nftId: number, price: string): Promise<void> {
    const tx = await this.fclService.addToMarket(nftId, price);
    const txObj = await tx.onceSealed();
    if (txObj.errorMessage) {
      throw new Error(txObj.errorMessage);
    }
  }
  async purchase(nftId: number): Promise<void> {
    const tx = await this.fclService.purchaseNFT(nftId);
    const txObj = await tx.onceSealed();
    if (txObj.errorMessage) {
      throw new Error(txObj.errorMessage);
    }
  }
  canPurchase(nftId: number): Promise<SaleState> {
    throw new Error('Method not implemented.');
  }
}
