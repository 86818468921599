
import React, { MouseEventHandler, useState } from 'react';
import debug from 'debug';

import { useParams } from 'react-router';
import { UserAccount } from '../../common/UserAccount';
import { Alert } from '../../components/alerts/Alert';
import { NFTCard, NFTCardSize } from '../../components/nft-card/NFTCard';
import { MetadataService } from '../../services/metadata/MetadataService';
import { Pack, PacksService } from '../../services/packs/PacksService';
import { getMetadataService, getPacksService } from '../../services/ServiceFactory';
import { useUserState } from '../../services/user/UserContext';
import { Page } from '../Page';

import './RevealPackPage.scss';

const log = debug('app:pages:profile:RevealPackPage');

const enum PageLoadState {
  Idle,
  ParsingId,
  ErrorParsingId,
  LoadingPack,
  ErrorLoadingPack,
  WaitingToOpenPack,
  OpeningPack,
  ErrorOpeningPack,
  LoadingMoments,
  ErrorLoadingMoments,
  OpeningMoments,
  Finished
}

type PageProps = {
  packIdStr: string,
  packsService: PacksService,
  metadataService: MetadataService,
  userState: UserAccount
};

type PageState = {
  packId: number,
  pack: Pack,
  loadState: PageLoadState,
  moments: Play.NFTMetadata[],
  opened: {
    [id: number]: boolean
  }
};

type OpenedMap = {
  [id: number]: boolean
};

type MomentCardProps = {
  moment: Play.NFTMetadata,
  opened: boolean,
  revealCallback: MouseEventHandler<HTMLButtonElement>
};

// TODO animation
function MomentCard({ moment, opened, revealCallback }: MomentCardProps) {
  return opened ? (
    <NFTCard size={NFTCardSize.Small} nftMetadata={moment} />
  ) : (
    <div className="card justify-content-center align-items-center m-1" style={{width: '18rem', height: '18rem'}}>
      <button className="btn btn-success" onClick={revealCallback} data-moment-id={moment.id}>Reveal</button>
    </div>
  );
}

function RevealSection({ moments }: { moments: Play.NFTMetadata[] }) {
  const [opened, setOpened] = useState({} as OpenedMap);
  
  function openMoment(e) {
    const momentId = e.currentTarget.dataset.momentId;
    const newOpened = {...opened};
    newOpened[momentId] = true;
    setOpened(newOpened);
  }

  return (
    <div className="d-flex flex-wrap justify-content-evenly">
      {moments.map(moment => (
        <MomentCard key={moment.id} moment={moment} opened={opened[moment.id] || false} revealCallback={openMoment} />
      ))}
    </div>
  )
}

function OpenPackPrompt({ pack, openPack }: { pack: Pack, openPack: () => void }) {
  return (
    <div className="open-pack-prompt-container d-flex flex-wrap justify-content-center">
      <div className="card bg-dark justify-content-center align-items-center m-1" style={{width: '18rem', height: '18rem'}}>
        <h5>{pack.name}</h5>
        <button className="btn btn-success" onClick={openPack}>Open pack?</button>
      </div>
    </div>
  );
}

class _RevealPackPage extends React.Component<PageProps, PageState> {
  constructor(props) {
    super(props);

    this.state = {
      packId: undefined,
      pack: undefined,
      loadState: PageLoadState.Idle,
      moments: undefined,
      opened: {}
    };

    this.openPack = this.openPack.bind(this);
    this.onOpenedAll = this.onOpenedAll.bind(this);
  }

  componentDidMount() {
    this.load();
  }

  private async load() {
    // parse id
    this.setState({ loadState: PageLoadState.ParsingId });
    let packId: number;
    try {
      packId = parseInt(this.props.packIdStr);
    } catch (e) {
      log('error occurred while parsing ID:', e);
      this.setState({ loadState: PageLoadState.ErrorParsingId });
      return;
    }

    // load pack
    this.setState({ loadState: PageLoadState.LoadingPack, packId });
    let pack: Pack;
    try {
      pack = await this.props.packsService.getSinglePack(packId);
    } catch (e) {
      log('error occurred while parsing ID:', e);
      this.setState({ loadState: PageLoadState.ErrorLoadingPack });
      return;
    }

    // prompt user
    this.setState({ loadState: PageLoadState.WaitingToOpenPack, pack });
  }

  private async openPack() {
    // open pack
    this.setState({ loadState: PageLoadState.OpeningPack });
    let momentIds: number[];
    try {
      momentIds = await this.props.packsService.openPack(this.state.packId);
    } catch (e) {
      log('error occurred while opening pack:', e);
      this.setState({ loadState: PageLoadState.ErrorOpeningPack });
      return;
    }
    if (!momentIds || momentIds.length === 0) {
      log('error occurred while opening pack: no moments acquired');
      this.setState({ loadState: PageLoadState.ErrorOpeningPack });
      return;
    }

    // load moments
    this.setState({ loadState: PageLoadState.LoadingMoments });
    let momentMap: Play.NFTMetadataMap;
    try {
      momentMap = await this.props.metadataService.loadNFTMetadata(
        momentIds, this.props.userState.address
      );
    } catch (e) {
      log('error occurred while loading metadata:', e);
      this.setState({ loadState: PageLoadState.ErrorLoadingMoments });
      return;
    }

    const moments = Object.values(momentMap);
    this.setState({ loadState: PageLoadState.OpeningMoments, moments });
  }

  private onOpenedAll() {
    this.setState({ loadState: PageLoadState.Finished });
  }

  private getContent(): JSX.Element {
    switch (this.state.loadState) {
    case PageLoadState.Idle: return <Alert.Info>Starting...</Alert.Info>;
    case PageLoadState.ParsingId: return <Alert.Info>Loading...</Alert.Info>;
    case PageLoadState.ErrorParsingId: return <Alert.Danger>Invalid Pack ID format.</Alert.Danger>;
    case PageLoadState.LoadingPack: return <Alert.Info>Loading pack...</Alert.Info>;
    case PageLoadState.ErrorLoadingPack: return <Alert.Danger>Error occurred while loading pack.</Alert.Danger>
    case PageLoadState.WaitingToOpenPack: return <OpenPackPrompt pack={this.state.pack} openPack={this.openPack} />
    case PageLoadState.OpeningPack: return <Alert.Info>Opening...</Alert.Info>;
    case PageLoadState.ErrorOpeningPack: return <Alert.Danger>Error occurred while opening pack.</Alert.Danger>;
    case PageLoadState.LoadingMoments: return <Alert.Info>Loading moments...</Alert.Info>;
    case PageLoadState.ErrorLoadingMoments: return <Alert.Danger>Error occurred while loading moments.</Alert.Danger>;
    case PageLoadState.OpeningMoments: return <RevealSection moments={this.state.moments} />;
    case PageLoadState.Finished: return <Alert.Info>Finished</Alert.Info>;
    default: return <Alert.Danger>Unknown page state.</Alert.Danger>
    }
  }

  render() {
    return (
      <Page title="Reveal" className="reveal-page container py-2">
        {this.getContent()}
      </Page>
    );
  }
}

export function RevealPackPage() {
  const { packId } = useParams();
  const userState = useUserState();
  const packsService = getPacksService();
  const metadataService = getMetadataService();

  return (
    <_RevealPackPage
      packIdStr={packId}
      packsService={packsService}
      metadataService={metadataService}
      userState={userState}
    />
  )
}
