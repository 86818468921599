import React from 'react';
import { Navigate } from 'react-router';
import { Page } from '../Page';

import './HomePage.scss';

export function HomePage() {
  return (
    <Page title="Home" className="home container py-2">
      <Navigate to="/packs" />
      <p>Homepage works</p>
    </Page>
  );
}
