import React from 'react';
import { debug } from 'debug';
import Decimal from 'decimal.js';
import { UserAccount } from '../../common/UserAccount';
import { environment } from '../../environments/environment';
import { ApiType } from '../../services/ServiceFactory';
import { useUserState } from '../../services/user/UserContext';
import { UserService } from '../../services/user/UserService';
import { useUserService } from '../../services/user/UserServiceContext';
import { Alert } from '../alerts/Alert';
import { Modal, ModalProps } from '../modal/Modal';
import { ethRegex, mockensRegex, playTokenRegex } from '../../consts';

import './TransferModal.scss';

const log = debug('app:components:transfer-modal:TransferModal');

export enum TransferType {
  Deposit = 'deposit', Withdraw = 'withdraw'
}

export type TransferModalProps = {
  show: boolean, // make this required
  deposit?: boolean,
  withdraw?: boolean,
  transferType?: TransferType
} & ModalProps;

type InternalProps = {
  userState: UserAccount,
  userService: UserService
} & TransferModalProps;

type InternalState = {
  transferType: TransferType | undefined,
  error: string,
  chainBalance: string,
  platformBalanceFull: string,
  platformBalance: string,
  transferError: string,
  transferAmount: string,
  transferValid: boolean,
  transferInProgress: boolean
};

const apiType = environment.userServiceApi as ApiType;

const chainCurrency = 'ETH';
const platformCurrency = apiType === ApiType.Fcl ? 'Play Tokens' :
  apiType === ApiType.Mock ? 'Mockens' :
    'Unknown';

const balanceRegex = apiType === ApiType.Fcl ? playTokenRegex :
  apiType === ApiType.Mock ? mockensRegex :
    ethRegex;

class _TransferModal extends React.Component<InternalProps, InternalState> {
  constructor(props: InternalProps) {
    super(props);

    this.state = {
      transferType: undefined,
      error: undefined,
      chainBalance: undefined,
      platformBalanceFull: undefined,
      platformBalance: undefined,
      transferError: undefined,
      transferAmount: '',
      transferValid: false,
      transferInProgress: false
    };

    this.dismiss = this.dismiss.bind(this);
    this.onAmountChange = this.onAmountChange.bind(this);
    this.startTransfer = this.startTransfer.bind(this);
  }

  componentDidMount() {
    this.determineTransferType();
    this.load();
  }

  componentDidUpdate(prevProps: Readonly<InternalProps>) {
    if (this.props.show) {
      if (!prevProps.show) {
        this.setState({ transferError: undefined, transferAmount: '' });
      }
      if (prevProps.transferType !== this.props.transferType) {
        this.determineTransferType();
      } else if (prevProps.deposit !== this.props.deposit) {
        this.determineTransferType();
      } else if (prevProps.withdraw !== this.props.withdraw) {
        this.determineTransferType();
      }
    }
    if (this.state.platformBalanceFull !== this.props.userState.balance) {
      this.roundPlatformBalance();
    }
  }

  async load() {
    this.props.userService.checkIfLinked(true); // forces to reload account
    this.loadChainBalance();
  }

  async loadChainBalance() {
    try {
      const chainBalanceFull = await this.props.userService.fetchChainBalance();
      const chainBalance = new Decimal(chainBalanceFull).toString(); // don't show unneeded places
      this.setState({ chainBalance });
    } catch (e) {
      log('Error occurred while loading chain balance', e);
      this.setState({ error: 'Error loading chain balance.' });
    }
  }

  onAmountChange(e: React.ChangeEvent<HTMLInputElement>) {
    const amt = e.target.value;
    this.setState({
      transferError: undefined,
      transferAmount: amt,
      transferValid: amt.length > 0 && balanceRegex.test(amt)
    });
  }

  startTransfer() {
    switch (this.state.transferType) {
    case TransferType.Deposit: this.startDeposit(); break;
    case TransferType.Withdraw: this.startWithdraw(); break;
    default: this.setState({ transferError: 'Invalid transfer type' });
    }
  }

  async startDeposit() {
    const amount = this.state.transferAmount;
    if (amount.length === 0) { return; }
    if (!balanceRegex.test(amount)) { return; }

    try {
      this.setState({ transferError: undefined, transferInProgress: true });
      await this.props.userService.depositIntoWallet(amount);
      this.setState({ transferInProgress: false });
      this.onFinished();
    } catch (e) {
      log('error occured while depositing', e);
      this.setState({ transferError: `Error: ${e.message}`, transferInProgress: false });
    }
  }

  async startWithdraw() {
    const amount = this.state.transferAmount;
    if (amount.length === 0) { return; }
    if (!balanceRegex.test(amount)) { return; }

    try {
      this.setState({ transferError: undefined, transferInProgress: true });
      await this.props.userService.withdrawFromWallet(amount);
      this.setState({ transferInProgress: false });
      this.onFinished();
    } catch (e) {
      log('error occurred while withdrawing', e);
      this.setState({ transferError: `Error: ${e.message}`, transferInProgress: false });
    }
  }

  dismiss() {
    this.props.onDismiss?.();
  }

  onFinished() {
    this.loadChainBalance();
    this.dismiss();
  }

  determineTransferType() {
    let transferType = undefined;
    if (this.props.transferType) {
      transferType = this.props.transferType;
    } else if (this.props.deposit) {
      transferType = TransferType.Deposit;
    } else if (this.props.withdraw) {
      transferType = TransferType.Withdraw;
    }

    this.setState({
      transferType,
      error: transferType ? undefined : 'Missing transfer type in transfer modal'
    });
  }

  roundPlatformBalance() {
    const platformBalanceFull = this.props.userState.balance;
    if (!platformBalanceFull) {
      this.setState({ platformBalanceFull, platformBalance: undefined });
      return;
    }

    const platformBalance = new Decimal(platformBalanceFull).toString();
    this.setState({ platformBalanceFull, platformBalance });
  }

  render() {
    const amount = this.state.transferAmount;
    const valid = this.state.transferValid;
    const amountDisplay = amount === '' ? `0.0 ${chainCurrency}` :
      valid ? `${amount} ${chainCurrency}` : '[invalid amount]';
    const amountClassName = !valid && amount !== '' ? 'text-danger' : '';
    const transferType = this.state.transferType;
    return (
      <Modal {...this.props} onDismiss={this.dismiss}>
        {this.props.show && this.state.error && (
          <Alert.Danger>{this.state.error}</Alert.Danger>
        )}
        {transferType === TransferType.Deposit && (
          <div className="mb-3">
            <span>{this.state.chainBalance} {chainCurrency}</span>
            <span className={amountClassName}>&nbsp;-&gt; {amountDisplay} -&gt;&nbsp;</span>
            <span>{this.state.platformBalance} {platformCurrency}</span>
          </div>
        )}
        {transferType === TransferType.Withdraw && (
          <div className="mb-3">
            <span>{this.state.platformBalance} {platformCurrency}</span>
            <span className={amountClassName}>&nbsp;-&gt; {amountDisplay} -&gt;&nbsp;</span>
            <span>{this.state.chainBalance} {chainCurrency}</span>
          </div>
        )}
        <div className="input-group mb-3">
          <input type="text"
            className="form-control"
            placeholder="Example: 0.001"
            onChange={this.onAmountChange}
            value={amount}
          />
          <span className="input-group-text">{chainCurrency}</span>
        </div>
        {this.state.transferError && (<Alert.Danger>{this.state.transferError}</Alert.Danger>)}
        <div className="mb-3">
          
          <button className="btn btn-success"
            onClick={this.startTransfer}
            disabled={!valid || this.state.transferInProgress}
          >
            {this.state.transferType === TransferType.Deposit && 'Deposit'}
            {this.state.transferType === TransferType.Withdraw && 'Withdraw'}
          </button>

        </div>
      </Modal>
    );
  }
}

export function TransferModal(props: TransferModalProps) {
  const userState = useUserState();
  const userService = useUserService();

  return (
    <_TransferModal
      {...props}
      userState={userState}
      userService={userService}
    />
  );
}
