import { Action, generateStoreContext } from './GenerateContext';

import { LoadState } from '../common/LoadState';
import { AppActionType } from './AppActionType';

const initialState = {
  readyState: LoadState.Idle,
  metadataMap: {} as Play.NFTMetadataMap
};

export type AppState = typeof initialState;

function reducer(state: AppState, action: Action) {
  let metadataMap: Play.NFTMetadataMap;
  switch (action.type) {
  case AppActionType.UpdateReadyState:
    if (!action.readyState) {
      throw new Error(`app-context: Missing "readyState" property for "${action.type}" action`)
    }
    return {
      ...state,
      readyState: action.readyState
    };
  case AppActionType.AddMetadata:
    if (action.metadata) {
      const metadata = action.metadata as Play.NFTMetadata;
      metadataMap = state.metadataMap;
      metadataMap[metadata.id] = metadata;
      return {
        ...state,
        metadataMap
      };
    }
    if (action.metadataMap) {
      metadataMap = {...state.metadataMap, ...action.metadataMap};
      return {
        ...state,
        metadataMap
      };
    }
    if (Array.isArray(action.metadatas)) {
      metadataMap = state.metadataMap;
      action.metadatas.forEach((metadata: Play.NFTMetadata) => {
        metadataMap[metadata.id] = metadata;
      });
      return {
        ...state,
        metadataMap
      };
    }
    throw new Error(`app-context: Missing required property in "${action.type}" action`);
  default:
    throw new Error(`app-context: Unknown action type "${action.type}"`);
  }
}

const {
  StateContext,
  DispatchContext,
  ContextProvider,
  ContextConsumer,
  withContext,
  useContextState,
  useContextDispatch
} = generateStoreContext(reducer, initialState, 'appState', 'appDispatch');

export {
  StateContext as AppStateContext,
  DispatchContext as AppDispatchContext,
  ContextProvider as AppProvider,
  ContextConsumer as AppConsumer,
  withContext as withApp,
  useContextState as useAppState,
  useContextDispatch as useAppDispatch,
  AppActionType
};
