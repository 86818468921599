import React, { useEffect } from 'react';
import { debug } from 'debug';
import { useLocation, useNavigate } from 'react-router';
import { useUserState } from '../services/user/UserContext';
import { UserPermission } from '../common/UserPermission';
import { environment } from '../environments/environment';
import { Alert } from '../components/alerts/Alert';

import './Page.scss';

const log = debug('app:pages:Page');

export type PageProps = {
  title?: string,
  className?: string,
  children?: React.ReactNode,
  restricted?: UserPermission | UserPermission[]
}

function createIsInArray<T>(array: T[]): (value: T) => boolean {
  return (value: T) => array.indexOf(value) !== -1;
}

function hasAllPermissions(userPerms: UserPermission[], requiredPerms: UserPermission[]) {
  return requiredPerms.every(createIsInArray(userPerms));
}

function BasePage({ className, children }: PageProps) {
  return (
    <div className={`page ${className ?? ''}`}>
      { children }
    </div>
  );
}

function RestrictedPage(props: PageProps) {
  const userState = useUserState();
  const navigate = useNavigate();
  const location = useLocation();
  const permissions = Array.isArray(props.restricted) ? props.restricted : [props.restricted];

  useEffect(() => {
    if (!userState.loggedIn) {
      navigate('/login?r=' + encodeURIComponent(location.pathname));
    }
  }, [userState]);

  if (!userState.loggedIn) {
    return null;
  }

  if (!hasAllPermissions(userState.permissions, permissions)) {
    return (
      <BasePage title="401" className="unauthorized container py-2">
        <Alert.Danger>
          Unauthorized.
        </Alert.Danger>
      </BasePage>
    );
  }
  return (
    <BasePage {...props} />
  );
}

export function Page(props: PageProps): JSX.Element {

  useEffect(() => {
    if (props.title) {
      document.title = `${props.title} - ${environment.pageTitle}`;
    } else {
      document.title = environment.pageTitle;
    }
  }, [props.title]);

  if (props.restricted) {
    return (
      <RestrictedPage {...props} />
    );
  }

  return (
    <BasePage {...props} />
  );
}
