
export const environment = {
  production: true,
  routerBasename: null,
  pageTitle: 'Sway Life (Staging)',
  accessNode: 'http://not.a.real.site',
  fclWalletDiscovery: 'http://not.a.rea.site',
  fclContractProfile: '0xNOTAPPLICABLE',
  userServiceApi: 'mock',
  marketplaceApi: 'mock',
  adminApi: 'mock',
  metadataApi: 'mock',
  packsApi: 'mock'
};
