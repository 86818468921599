import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router';

import { NavLink } from '../../components/nav-link/NavLink';
import { environment } from '../../environments/environment';
import { useUserState } from '../../services/user/UserContext';
import { Page } from '../Page';
import { MyMomentsSection } from './MyMomentsSection';
import { MyPacksSection } from './MyPacksSection';

import { getMetadataService } from '../../services/ServiceFactory';
import { useUserService } from '../../services/user/UserServiceContext';

import './ProfilePage.scss';
import { Alert } from '../../components/alerts/Alert';
import { InventoryHeader } from '../../components/inventory-header/InventoryHeader';

export type ProfilePageProps = {
  moments?: boolean,
  packs?: boolean
};

function ProfileHeader({ profileId }: { profileId: string }) {
  const userState = useUserState();
  const userService = useUserService();
  const metadataService = getMetadataService();
  const [name, setName] = useState('Loading...');
  const [nftItems, setNftItems] = useState([] as Play.NFTMetadata[]);

  async function load() {
    // TODO profileId might not be the same as address in the future
    const address = profileId === 'me' ? userState.address : profileId;

    const account = await userService.loadPublicAccount(address);
    const nfts = account.nfts ?? [];

    setName(account.name);

    if (nfts.length === 0) { return; }

    const metadataMap = await metadataService.loadNFTMetadata(nfts, address);

    setNftItems(Object.values(metadataMap));
  }

  useEffect(() => {
    load();
  }, [profileId]);

  return (
    <>
      <InventoryHeader title="COLLECTION">
        <NavLink className="btn btn-secondary" end activeClassName="current" to={`/profile/${profileId}`}>
            Moments
        </NavLink>
        <NavLink className="btn btn-secondary" activeClassName="current" to={`/profile/${profileId}/packs`}>
            Packs
        </NavLink>
      </InventoryHeader>

      <div className="inventory-info">
        <p>
          Most of the time, Moments purchases on the marketplace appear quickly in your collecion, but it may take up to 15 min at times.
          Check your activity feed and receipts in case your oder was not successful. <a href="#">Read more</a>
        </p>
      </div>
    </>
  );
}

export function ProfilePage(props: ProfilePageProps): JSX.Element {
  const { profileId } = useParams();
  const userState = useUserState();

  if (!profileId) {
    return (
      <Page className="profile container py-2">
        <Navigate to="/profile/me" />
        <Alert.Info>Loading...</Alert.Info>
      </Page>
    );
  }

  if (profileId === 'me' && !userState.loggedIn) {
    return (
      <Page className="profile container py-2">
        <Navigate to={'/login?r=' + encodeURIComponent('/profile/me')} />
        <Alert.Info>Loading...</Alert.Info>
      </Page>
    );
  }

  useEffect(() => {
    const possessive = profileId === 'me' ? 'My' : userState.name;
    if (props.moments) {
      document.title = `${possessive} Moments - ${environment.pageTitle}`;
    } else if (props.packs) {
      document.title = `${possessive} Packs - ${environment.pageTitle}`;
    } else {
      document.title = `${possessive} Profile - ${environment.pageTitle}`;
    }
  }, [props.moments, props.packs, userState.name]);

  return (
    <Page className="profile container py-2">
      <ProfileHeader profileId={profileId} />
      {props.moments && (
        <MyMomentsSection profileId={profileId} />
      )}
      {props.packs && (
        <MyPacksSection profileId={profileId} />
      )}
    </Page>
  );
}
