import React from 'react';

export type Action = {
  type: string,
  [key: string]: any
};

export type Reducer<T> = (state: T, action: Action) => T;

export function generateStoreContext<T>(
  reducer: Reducer<T>, initialState: T,
  stateName: string, dispatchName: string
) {
  const StateContext = React.createContext<T>(undefined);
  const DispatchContext = React.createContext<React.Dispatch<Action>>(undefined);

  function ContextProvider({ children }) {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    return (
      <StateContext.Provider value={state}>
        <DispatchContext.Provider value={dispatch}>
          {children}
        </DispatchContext.Provider>
      </StateContext.Provider>
    )
  }

  function useContextState() {
    const context = React.useContext(StateContext);
    if (context === undefined) {
      throw new Error('useContextState must be used within an ContextProvider');
    }
    return context;
  }

  function useContextDispatch() {
    const context = React.useContext(DispatchContext);
    if (context === undefined) {
      throw new Error('useContextDispatch must be used within an ContextProvider');
    }
    return context;
  }

  function ContextConsumer({children}) {
    return (
      <StateContext.Consumer>
        { stateContext => (
          <DispatchContext.Consumer>
            { dispatchContext => {
              if (stateContext === undefined || dispatchContext == undefined) {
                throw new Error('ContextConsumer must be used within an ContextProvider');
              }
              return children([stateContext, dispatchContext]);
            }}
          </DispatchContext.Consumer>
        )}
      </StateContext.Consumer>
    )
  }

  function withContext(Component) {
    return props => (
      <ContextConsumer>
        {([stateContext, dispatchContext]) => {
          const dynamicProps = {...props};
          dynamicProps[stateName] = stateContext;
          dynamicProps[dispatchName] = dispatchContext;
          return (
            <Component
              {...dynamicProps} />
          );
        }}
      </ContextConsumer>
    );
  }

  return {
    StateContext,
    DispatchContext,
    ContextProvider,
    ContextConsumer,
    withContext,
    useContextState,
    useContextDispatch
  }
}
