
import React, { FunctionComponent } from 'react';

import iconZoom from '../../assets/svg/zoom.svg';

import './MomentsFilter.scss';

export const Filters = [ 'Player', 'Rarity', 'Episode', 'Collection', 'Location', 'Tone', 'Set' ];
Object.freeze(Filters);

export type MomentsFiltersState = {
  showFilters: boolean,
  expandedFilters: boolean[],
  valuesFilters: string[]
}

type MomentsFilterProps = {
  label: string,
  expanded: boolean,
  setExpanded: (value: boolean) => void,
  value: string,
  setValue: (value: string) => void
}

export const MomentsFilter = React.memo((({label, expanded, setExpanded, value, setValue}) => {
  const expandedClass = expanded ? ' expanded' : '';
  return <div className={ `moments-filters-box${expandedClass}` } key={ label }>
    <div className="moments-filter-header d-flex align-items-center justify-content-between"
      onClick={ () => setExpanded(!expanded) } >
      <div className={ `moments-filter-label${expandedClass}` }>{ label }</div>
      <div className={ `moments-filter-arrow moments-filter-arrow-${expanded ? 'up' : 'down' }` } />
    </div>
    { expanded && <div className="moments-filter-input d-flex align-items-center">
      <img className="moments-filter-input-zoom" src={iconZoom}></img>
      <input value={value} type='text' placeholder={ label } onChange={ e => setValue(e.target.value)} />
    </div>}
  </div>
}) as FunctionComponent<MomentsFilterProps>);
