import React, { useMemo } from 'react';
import ReactDOM from 'react-dom';
import './MockLoginScreen.scss';
import { MockLoginUser, mockUsers } from './MockUtils';

export enum MockLoginActionType {
  Add, Select, Close
}

export type MockLoginCallback = (actionType: MockLoginActionType, data?: any) => void;

export function MockLoginScreen({ callback }: { callback: MockLoginCallback }): JSX.Element {

  const users: MockLoginUser[] = useMemo(() => [...mockUsers], []);

  function chooseUser(e) {
    const address = e.currentTarget.dataset.address;
    const user = users.find(user => user.address === address);
    callback(MockLoginActionType.Select, user);
  }

  function close() {
    callback(MockLoginActionType.Close);
  }

  return (
    <div className="mock-login-screen card">
      <div className="card-header">
        Login
        <button type="button" className="btn-close" aria-label="Close" onClick={close}></button>
      </div>
      { !users ? (
        <div className="card-body">
          Loading...
        </div>
      ) : (
        <ul className="list-group list-group-flush">
          { users.map(user => (
            <li key={user.address} className="list-group-item" onClick={chooseUser} data-address={user.address}>
              <p>{user.name}</p>
              <small className="text-muted">{user.address}</small>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export function createLoginScreen(callback: MockLoginCallback): HTMLDivElement {
  const div = document.createElement('div');
  div.classList.add('mock-login-screen-overlay');
  div.addEventListener('click', (e) => {
    if (e.target === e.currentTarget) {
      callback(MockLoginActionType.Close);
    }
  }, false);

  ReactDOM.render(
    <React.StrictMode>
      <MockLoginScreen callback={callback} />
    </React.StrictMode>,
    div
  );

  return div;
}

export function destroyLoginScreen(element: HTMLDivElement): void {
  ReactDOM.unmountComponentAtNode(element);
}
