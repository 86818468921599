import React from 'react';
import { Pack, PackSale, PacksService } from '../../services/packs/PacksService';
import { getPacksService } from '../../services/ServiceFactory';
import { Page } from '../Page';
import { PackSaleElement } from '../../components/packs/PackSaleElement';
import { Alert } from '../../components/alerts/Alert';

import './PacksPage.scss';

type PacksPageProps = {
  packsService: PacksService
};

type PacksPageState = {
  loading: boolean,
  featuredPackSales: PackSale[]
};

class InternalPacksPage extends React.Component<PacksPageProps, PacksPageState> {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      featuredPackSales: []
    };
  }

  componentDidMount() {
    this.load();
  }

  async load() {
    const featuredPackSales = await this.props.packsService.getFeaturedSales();
    this.setState({
      loading: false,
      featuredPackSales
    });
  }

  render() {
    return (
      <Page title="Packs" className="packs">
        <div className="packs-top">
          <div className="container">
            { this.state.loading ? (
              <Alert.Info>Loading...</Alert.Info>
            ) : this.state.featuredPackSales.length === 0 ? (
              <Alert.Info>Empty.</Alert.Info>
            ) : (
              <div className="packs-container">
                {this.state.featuredPackSales.map(sale => (
                  <PackSaleElement key={sale.saleId} packSale={sale} baseUrl="/packs/" />
                ))}
              </div>
            )}
          </div>
        </div>
      </Page>
    );
  }
}

export function PacksPage() {
  const packsService = getPacksService();
  return (
    <InternalPacksPage
      packsService={packsService}
    />
  );
}
