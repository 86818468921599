import { Page } from '../Page';

import './HelpPage.scss';

export function HelpPage() {
  return (
    <Page title="Help" className="help-page container py-2">
      <p>Help page TODO...</p>
    </Page>
  );
}
