import { CurrentUser, CurrentUserObject } from '@onflow/fcl';
import debug from 'debug';

import { Action } from '../../context/GenerateContext';
import { environment } from '../../environments/environment';
import { FclService } from '../fcl/FclService';
import { UserAccount, UserAccountLike } from '../../common/UserAccount';
import { UserActionType } from './UserActionType';
import { UserPermission } from '../../common/UserPermission';
import { UserService } from './UserService';
import { MetadataService } from '../metadata/MetadataService';

const log = debug('app:services:user:FclUserService');

type Resolve = () => void;

export class FclUserService implements UserService {

  readonly logInPopUp = false;

  private initialized = false;
  private dispatch: (action: Action) => void;
  private fclUser: CurrentUserObject;
  private fclUserFns: CurrentUser;
  private userState: UserAccount;
  private firstLogin = true;
  private dispatchResolves: Resolve[] = [];
  private userStateResolves: Resolve[] = [];
  private initResolves: Resolve[] = [];
    
  constructor(
    private fclService: FclService,
    private metadataService: MetadataService
  ) {
    fclService.subscribeToUserChange(this.setFCLUser.bind(this))
  }

  private async setFCLUser(fclUser: CurrentUserObject): Promise<void> {
    await this.waitForDispatch();
    if (fclUser?.loggedIn) {
      if (this.firstLogin) {
        // first time logging in this cycle
        this.firstLogin = false;
        this.fclUser = fclUser;
        this.fclUserFns = this.fclService.currentUserInterface();
        const user: UserAccountLike = {
          address: fclUser.addr,
          name: fclUser.addr,
          checked: false,
          linked: false,
          balance: null,
          nfts: [],
          permissions: [UserPermission.User]
        };
  
        if (user.address === environment.fclContractProfile) {
          user.permissions.push(UserPermission.Admin);
          user.permissions.push(UserPermission.Service);
        }
  
        this.dispatch?.({ type: UserActionType.LogIn, ...user });
      } else {
        // just update props
        this.dispatch?.({ type: UserActionType.UpdateAddress, address: fclUser.addr });
        this.dispatch?.({ type: UserActionType.UpdateName, name: fclUser.addr });
      }
    } else {
      // this clears the user state
      this.fclUser = undefined;
      this.fclUserFns = undefined;
      this.dispatch?.({ type: UserActionType.LogOut });
      this.firstLogin = true;
    }

    if (fclUser && !this.initialized) {
      this.initialized = true;
      this.initResolves.forEach(resolve => resolve());
    }
  }

  private waitForDispatch(): Promise<void> {
    if (this.hasDispatch()) { return Promise.resolve(); }

    return new Promise(resolve => {
      this.dispatchResolves.push(resolve);
    });
  }

  private waitForUserState(): Promise<void> {
    if (this.userState) { return Promise.resolve(); }

    return new Promise(resolve => {
      this.userStateResolves.push(resolve);
    });
  }

  hasInitialized(): boolean {
    return this.initialized;
  }

  waitForInit(): Promise<void> {
    if (this.initialized) { return Promise.resolve(); }

    return new Promise(resolve => this.initResolves.push(resolve));
  }

  hasDispatch(): boolean {
    return !!this.dispatch;
  }

  setDispatch(dispatch: (action: Action) => void): void {
    this.dispatch = dispatch;
    this.dispatchResolves.forEach(resolve => resolve());
    this.dispatchResolves = [];
  }

  setUserState(state: UserAccount): void {
    this.userState = state;
    this.userStateResolves.forEach(resolve => resolve());
    this.userStateResolves = [];
  }

  signUp(): void {
    this.fclService.signUp()
  }

  logIn(): void {
    this.fclService.logIn()
  }

  logOut(): void {
    this.fclService.logOut()
  }
  async checkIfLinked(force: boolean = false): Promise<boolean> {
    await this.waitForUserState();
    if (!force && this.userState.checked) { return this.userState.linked }

    const details = await this.fclService.loadAccount(this.userState.address);
    this.dispatch({ type: UserActionType.UpdateLinked, linked: details.linked });
    this.dispatch({ type: UserActionType.UpdateBalance, balance: details.balance });
    this.dispatch({ type: UserActionType.UpdateNFTs, nfts: details.nfts });
    this.dispatch({ type: UserActionType.UpdateChecked, checked: true });
    return details.linked;
  }
  async maybeLink(): Promise<void> {
    const linked = await this.checkIfLinked();

    if (linked) { return; }

    return this.link();
  }
  async link(): Promise<void> {
    const transaction = await this.fclService.linkAccount(this.fclUserFns.authorization);
    const txObj = await transaction.onceSealed();
    if (txObj.errorMessage) {
      log(txObj.errorMessage);
      throw new Error('Linking account failed');
    }

    const linked = await this.checkIfLinked(true);
    if (!linked) {
      throw new Error('Linking account failed (cause unknown)');
    }
  }
  async loadNFTMetadata(): Promise<Play.NFTMetadataMap> {
    await this.waitForUserState();
    if (!this.userState.nfts) { return undefined; }

    return this.metadataService.loadNFTMetadata(this.userState.nfts, this.userState.address);
  }
  async loadSingleMetadata(id: number): Promise<Play.NFTMetadata> {
    await this.waitForUserState();
    if (!this.userState.nfts) { return undefined; }
    if (this.userState.nfts.indexOf(id) === -1) { return undefined; }

    return this.metadataService.loadSingleMetadata(id, this.userState.address);
  }
  async loadPublicAccount(address: string): Promise<Play.PublicAccountDetails> {
    throw new Error('TODO');
  }

  getServiceAddress(): Promise<string> {
    return this.fclService.getServiceAddress();
  }

  getAccountDetails(address: string): Play.AccountDetails {
    throw new Error('FclUserService.getAccountDetails(): Not applicable');
  }

  putAccountDetails(address: string, details: Play.AccountDetails): void {
    throw new Error('FclUserService.putAccountDetails(): Not applicable');
  }
}
