import React from 'react';
import { NavLink as BaseNavLink } from 'react-router-dom';

import './NavLink.scss';

type NavLinkProps = {
  activeClassName: string,
  activeStyle: object,
  to: string,
  [key: string]: any
}

export const NavLink = React.forwardRef<HTMLAnchorElement, NavLinkProps>(
  ({ activeClassName, activeStyle, ...props }, ref) => (
    <BaseNavLink
      ref={ref}
      {...props}
      className={({ isActive }) =>
        [
          props.className,
          isActive ? activeClassName : null
        ]
          .filter(Boolean)
          .join(' ')
      }
      style={({ isActive }) => ({
        ...props.style,
        ...(isActive ? activeStyle : null)
      })}
    />
  )
);
