import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { PackSale } from '../../services/packs/PacksService';

import './PackSaleElement.scss';

export function PackSaleElement({ packSale, baseUrl = undefined }: { packSale: PackSale, baseUrl?: string }) {

  const [highlighted, setHighlighted] = useState(false);

  const rarityClassName = packSale?.rarity.toLocaleLowerCase();
  const highlightedClassName = highlighted ? ' highlighted' : '';

  return (
    <Link to={baseUrl + packSale.saleId} style={{ textDecoration: 'none' }}
      onMouseEnter={ () => setHighlighted(true) }
      onMouseLeave={ () => setHighlighted(false) }
      className={`packs-sale-graphics ${rarityClassName}${highlightedClassName}`}>
      <div className={ `packs-sale-element ${rarityClassName}` }>
        <img src={packSale.imgSrc} className={ `packs-sale-package ${rarityClassName}` } />
        <div className={ `packs-sale-info ${rarityClassName}`}>
          <div className="packs-sale-info-title"> {packSale.name} </div>
          <div>
            <span className="packs-sale-info-rarity" > { rarityClassName } </span>
            <span className="packs-sale-info-details"> | {packSale.availableAt} MOMENT COLLECTIBLES </span>
          </div>
          <div className={ `get-pack-button ${rarityClassName}${highlightedClassName}` }>
            <div style={{ margin: 'auto' }}>{ 'GET YOUR PACK'}</div>
          </div>
        </div>
      </div>
    </Link>
  );
}
