import React from 'react';
import debug from 'debug';

import { UserAccount } from '../../common/UserAccount';
import { Alert } from '../../components/alerts/Alert';
import { Pack, PacksService } from '../../services/packs/PacksService';
import { getPacksService } from '../../services/ServiceFactory';
import { useUserState } from '../../services/user/UserContext';
import { SinglePackElement } from '../../components/packs/SinglePackElement';
import { UserService } from '../../services/user/UserService';
import { useUserService } from '../../services/user/UserServiceContext';
import { FilterHeader } from '../../components/filters/FilterHeader';

import './MyPacksSection.scss';

const log = debug('app:pages:profile:MyPacksSection');

export type MyPacksSectionProps = {
  profileId: string
};

type PageProps = {
  profileId: string,
  packsService: PacksService,
  userState: UserAccount,
  userService: UserService
};

type PageState = {
  packs: Pack[],
  loading: boolean,
  error: string
};

class _MyPacksSection extends React.Component<PageProps, PageState> {
  constructor(props) {
    super(props);

    this.state = {
      packs: undefined,
      loading: true,
      error: undefined
    };
  }

  componentDidMount() {
    this.load();
  }

  private async load() {
    if (this.props.profileId === 'me' && !this.props.userState.loggedIn) {
      this.setState({ loading: false, error: 'Not logged in.' });
      return;
    }
    const linked = await this.props.userService.checkIfLinked();

    if (!linked) {
      this.setState({ loading: false, error: 'Not linked.' });
      return;
    }

    const profileId = this.props.profileId === 'me' ?
      this.props.userState.address : this.props.profileId;

    let accountDetails: Play.PublicAccountDetails;
    try {
      accountDetails = await this.props.userService.loadPublicAccount(profileId);
    } catch (e) {
      log('error occurred while loading public account:', e);
      this.setState({ loading: false, error: 'Account not linked' });
      return;
    }

    if (accountDetails.nfts === undefined) {
      this.setState({ loading: false, error: 'Account not linked' });
      return;
    }

    const packIds = accountDetails.packs;

    if (!packIds || packIds.length === 0) {
      this.setState({ loading: false, packs: [] });
      return;
    }

    const packs = await this.props.packsService.getPacks(packIds);

    this.setState({ loading: false, packs });
  }

  render() {
    const packsByRarity = this.state.packs?.reduce((a, pack) => {
      if (!a[pack.rarity]) {
        a[pack.rarity] = []
      }

      a[pack.rarity].push(pack);

      return a;
    }, {} as Partial<Record<string, Pack[]>>);
    const packs = packsByRarity
      ? Object.keys(packsByRarity)
        .map(rarity => ({
          pack: packsByRarity[rarity][0],
          count: packsByRarity[rarity].length
        }))
      : [];

    return (
      <div className="my-packs-section py-2">
        {this.state.loading ? (
          <Alert.Info>Loading...</Alert.Info>
        ) : (
          <div>
            <FilterHeader>
              <div>
                <button className="btn btn-primary btn-sort">
                  <span><b>Newest Received</b></span>
                </button>
              </div>

              <div>
                <p> { this.state.packs.length } <small> Packs total</small></p>
              </div>
            </FilterHeader>

            {this.state.error && (
              <Alert.Danger>{this.state.error}</Alert.Danger>
            )}
            {!this.state.packs ? (
              <Alert.Warning>Error loading packs</Alert.Warning>
            ) : this.state.packs.length === 0 ? (
              <Alert.Info>Empty</Alert.Info>
            ) : (
              <div className="d-flex flex-wrap justify-content-start">
                {packs.map(({pack, count }) => (
                  <SinglePackElement key={pack.id} pack={pack} count={count} baseUrl={`/profile/${this.props.profileId}/packs/`} />
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export function MyPacksSection(props: MyPacksSectionProps) {
  const packsService = getPacksService();
  const userState = useUserState();
  const userService = useUserService();

  return <_MyPacksSection
    profileId={props.profileId}
    packsService={packsService}
    userState={userState}
    userService={userService}
  />
}
