import React from 'react';

import './PackSaleDetails.scss';

export function PackSaleDetails(props: { rarity: string }) {
  const [collapsed, setCollapsed] = React.useState(false);

  return (
    <div className={`pack-sale-details ${props.rarity} ${collapsed ? 'collapsed' : 'expanded'}`}>
      <button
        onClick={() => setCollapsed(x => !x)} 
        className="header">
        <h1>Pack Details</h1>
      </button>

      <div className="content">
        <p>
          On behalf of the entire Top Shot team and the greater community, thank
          you for joining us. We’re happy you’re here, and we can't wait to show
          you around.
        </p>

        <p>
          This pack of Base Set Moments is what we call a 'Starter Pack,'
          meaning it's exclusively available for new collectors who haven't had
          the chance to rip open their first pack yet. Join this drop and
          instantly get your first Base Set pack for a chance at some of the
          NBA's best Moments.
        </p>

        <a href="#">View the complete pack list here.</a>

        <h3>Pack Requirements</h3>
        <p>
          To qualify for this pack, collectors must have never bought a pack
          before.
        </p>

        <h3>Base Set Key Stats</h3>
        <p>
          Moments in each pack: 3
          <br />
          Packs per collector: 1
          <br />
          Cost per pack: $9 USD
        </p>
      </div>
    </div>
  );
}
