
import { environment } from '../environments/environment';

import { AdminService } from './admin/AdminService';
import { FclAdminService } from './admin/FclAdminService';
import { MockAdminService } from './admin/MockAdminService';
import { FclService } from './fcl/FclService';
import { Marketplace } from './marketplace/Marketplace';
import { FclMarketplace } from './marketplace/FclMarketplace';
import { MockMarketplace } from './marketplace/MockMarketplace';
import { MetadataService } from './metadata/MetadataService';
import { FclMetadataService } from './metadata/FclMetadataService';
import { MockMetadataService } from './metadata/MockMetadataService';
import { MockStorageService } from './mock/MockStorageService';
import { UserService } from './user/UserService';
import { MockUserService } from './user/MockUserService';
import { FclUserService } from './user/FclUserService';
import { PacksService } from './packs/PacksService';
import { RestPacksService } from './packs/RestPacksService';
import { MockPacksService } from './packs/MockPacksService';

export enum ApiType {
  Fcl = 'fcl',
  Mock = 'mock',
  Rest = 'rest'
}

enum ServiceType {
  Marketplace = 'marketplace',
  UserService = 'userService',
  AdminService = 'adminService',
  MetadataService = 'metadataService',
  PacksService = 'packsService',
  MockStorageService = 'mockStorageService'
}

type Cache = {
  [key in ApiType]?: {
    [key in ServiceType]?: any
  }
};

const serviceCreators: {
  [key in ServiceType]: (apiType: ApiType) => any
} = {
  marketplace: createMarketplace,
  userService: createUserService,
  adminService: createAdminService,
  metadataService: createMetadataService,
  packsService: createPacksService,
  mockStorageService: createMockStorageService
};

const cache: Cache = {};

let fclService: FclService;

function getFclService(): FclService {
  if (!fclService) {
    fclService = new FclService();
  }
  return fclService;
}

function createMarketplace(apiType: ApiType): Marketplace {
  switch (apiType) {
  case ApiType.Fcl: return new FclMarketplace(getFclService());
  case ApiType.Mock: return new MockMarketplace(getMockService(), getUserService());
  case ApiType.Rest: throw new Error(`Unsupported api type "${apiType}"`);
  default: throw new Error(`Unknown ApiType ${apiType}`);
  }
}

function createUserService(apiType: ApiType): UserService {
  switch (apiType) {
  case ApiType.Fcl: return new FclUserService(getFclService(), getMetadataService());
  case ApiType.Mock: return new MockUserService(getMockService(), getMetadataService());
  case ApiType.Rest: throw new Error(`Unsupported api type "${apiType}"`);
  default: throw new Error(`Unknown ApiType ${apiType}`);
  }
}

function createAdminService(apiType: ApiType): AdminService {
  switch (apiType) {
  case ApiType.Fcl: return new FclAdminService(getFclService());
  case ApiType.Mock: return new MockAdminService(
    getMockService(), getMetadataService(), getUserService(), getPacksService()
  );
  case ApiType.Rest: throw new Error(`Unsupported api type "${apiType}"`);
  default: throw new Error(`Unknown ApiType ${apiType}`);
  }
}

function createPacksService(apiType: ApiType): PacksService {
  switch (apiType) {
  case ApiType.Fcl: throw new Error(`Unsupported api type "${apiType}"`);
  case ApiType.Mock: return new MockPacksService(getMockService(), getUserService(), getMetadataService());
  case ApiType.Rest: return new RestPacksService();
  default: throw new Error(`Unknown ApiType ${apiType}`);
  }
}

function createMetadataService(apiType: ApiType): MetadataService {
  switch (apiType) {
  case ApiType.Fcl: return new FclMetadataService(getFclService());
  case ApiType.Mock: return new MockMetadataService(getMockService());
  case ApiType.Rest: throw new Error(`Unsupported api type "${apiType}"`);
  default: throw new Error(`Unknown ApiType ${apiType}`);
  }
}

function createMockStorageService(apiType: ApiType): MockStorageService {
  switch (apiType) {
  case ApiType.Fcl: throw new Error(`ApiType ${apiType} not applicable for mockApi`);
  case ApiType.Mock: return new MockStorageService();
  case ApiType.Rest: throw new Error(`Unsupported api type "${apiType}"`);
  default: throw new Error(`Unknown ApiType ${apiType}`);
  }
}

function getOrCreateService(apiType: ApiType, serviceType: ServiceType): any {
  if (!cache[apiType]) {
    cache[apiType] = {};
  }
  if (!cache[apiType][serviceType]) {
    cache[apiType][serviceType] = serviceCreators[serviceType](apiType);
  }

  return cache[apiType][serviceType];
}

export function getMarketplace(): Marketplace {
  const apiType = environment.marketplaceApi as ApiType;
  return getOrCreateService(apiType, ServiceType.Marketplace);
}

export function getUserService(): UserService {
  const apiType = environment.userServiceApi as ApiType;
  return getOrCreateService(apiType, ServiceType.UserService);
}

export function getAdminService(): AdminService {
  const apiType = environment.adminApi as ApiType;
  return getOrCreateService(apiType, ServiceType.AdminService);
}

export function getMetadataService(): MetadataService {
  const apiType = environment.metadataApi as ApiType;
  return getOrCreateService(apiType, ServiceType.MetadataService);
}

export function getPacksService(): PacksService {
  const apiType = environment.packsApi as ApiType;
  return getOrCreateService(apiType, ServiceType.PacksService);
}

export function getMockService(): MockStorageService {
  const apiType = ApiType.Mock;
  return getOrCreateService(apiType, ServiceType.MockStorageService);
}
