import React from 'react';
import { Decimal } from 'decimal.js';
import { UserAccount } from '../../common/UserAccount';
import { Action } from '../../context/GenerateContext';
import { MockStorageService } from '../mock/MockStorageService';
import { UserService } from '../user/UserService';
import { Pack, PackSale, PacksService, Rarity } from './PacksService';
import { MetadataService } from '../metadata/MetadataService';
import {  mockensPlaces } from '../../consts';
import { SaleState } from '../../common/SaleState';

type PacksData = {
  featured: number[],
  midPage: number[],
  packSales: PackSale[],
  packMap: {
    [id: number]: Pack
  },
  saleIdPool: number,
  packIdPool: number
};

const packsDataKey = 'packsData';

export class MockPacksService implements PacksService {
  private packsData: PacksData;
  private userDispatch: React.Dispatch<Action>;
  private userState: UserAccount;

  constructor(
    private mockStorageService: MockStorageService,
    private userService: UserService,
    private metadataService: MetadataService
  ) {
    try {
      this.packsData = mockStorageService.getJson(packsDataKey);
    } catch (e) {
      // do nothing
    }
    if (!this.packsData) {
      this.packsData = {
        featured: [],
        midPage: [],
        packSales: [],
        packMap: {},
        saleIdPool: 1,
        packIdPool: 1
      };
      this.savePacksData();
    }
  }
  private savePacksData(): void {
    this.mockStorageService.putJson(packsDataKey, this.packsData);
  }
  private isPackFeatured(saleId: number): boolean {
    return this.packsData.featured.indexOf(saleId) !== -1;
  }
  private isPackMidPage(saleId: number): boolean {
    return this.packsData.midPage.indexOf(saleId) !== -1;
  }
  setUserDispatch(dispatch: React.Dispatch<Action>): void {
    this.userDispatch = dispatch;
  }
  setUserState(state: UserAccount): void {
    this.userState = state;
  }
  async getFeaturedSales(): Promise<PackSale[]> {
    return this.packsData.packSales
      .filter(sale => this.isPackFeatured(sale.saleId))
      .map(sale => ({...sale})); // clone
  }
  async getMidPageSales(): Promise<PackSale[]> {
    return this.packsData.packSales
      .filter(sale => this.isPackMidPage(sale.saleId))
      .map(sale => ({...sale})); // clone
  }
  async getSales(): Promise<PackSale[]> {
    return this.packsData.packSales.map(sale => ({...sale}));
  }
  async getSingleSale(saleId: number): Promise<PackSale> {
    const packSale = this.packsData.packSales.find(sale => sale.saleId === saleId);
    if (!packSale) { return undefined }
    return packSale;
  }
  async getPacks(packIds: number[]): Promise<Pack[]> {
    const packs = packIds.map(packId => this.packsData.packMap[packId])
      .filter(pack => !!pack); // filter undefined
    return packs;
  }
  async getSinglePack(packId: number): Promise<Pack> {
    const pack = this.packsData.packMap[packId];
    if (!pack) { return undefined; }
    return pack;
  }
  async buyPack(saleId: number): Promise<number> {
    if (!this.userState?.loggedIn) {
      throw new Error('Not logged in');
    }

    const linked = await this.userService.checkIfLinked(true);
    if (!linked) {
      throw new Error('Account not linked');
    }

    const packSale = this.packsData.packSales.find(sale => sale.saleId === saleId);
    if (!packSale) {
      throw new Error(`PackSale ${saleId} not found`);
    }
    
    if (packSale.packIds.length === 0) {
      throw new Error(`PackSale ${saleId} out of stock`);
    }

    const salePrice = new Decimal(packSale.price);
    const balance = new Decimal(this.userState.balance);
    if (balance.lessThan(salePrice)) {
      throw new Error(`Account cannot afford PackSale ${saleId}`);
    }

    // time to buy

    const serviceAddress = await this.userService.getServiceAddress();

    // deduct
    const newBalance = balance.sub(salePrice);
    const accountDetails = this.userService.getAccountDetails(this.userState.address);
    const serviceDetails = this.userService.getAccountDetails(serviceAddress);
    const serviceBalance = new Decimal(serviceDetails.balance);
    const newServiceBalance = serviceBalance.add(salePrice);
    
    // pull pack
    const packId = packSale.packIds.shift();
    const packs = [...accountDetails.packs] || [];
    packs.push(packId);

    this.savePacksData();
    this.userService.putAccountDetails(this.userState.address, {
      ...accountDetails,
      balance: newBalance.toFixed(mockensPlaces),
      packs
    });
    this.userService.putAccountDetails(serviceAddress, {
      ...serviceDetails,
      balance: newServiceBalance.toFixed(mockensPlaces)
    });

    return packId;
  }
  async openPack(packId: number): Promise<number[]> {
    const linked = await this.userService.checkIfLinked();
    if (!linked) {
      throw new Error('Account not linked');
    }

    if (!this.packsData.packMap[packId]) {
      throw new Error(`Pack ID ${packId} not found`);
    }

    const accountDetails = this.userService.getAccountDetails(this.userState.address);
    const userPacks = accountDetails.packs?.slice() ?? []; // clone
    const packIndex = userPacks ? userPacks.indexOf(packId) : -1;
    const userNfts = accountDetails.nfts?.slice() ?? []; // clone

    // does account own this pack
    if (packIndex === -1) {
      throw new Error(`Account does not own pack ID ${packId}`);
    }
    
    // remove from account
    userPacks.splice(packIndex, 1);

    // open
    const pack = this.packsData.packMap[packId];
    const nftIds = pack.nftIds;
    pack.opened = true;

    const serviceAddress = await this.userService.getServiceAddress();

    // erase reservedFor
    if (this.metadataService.putMetadatas) {
      const metadataMap = await this.metadataService.loadNFTMetadata(nftIds, serviceAddress);
      const metadatas = Object.values(metadataMap).map(metadata => {
        delete metadata.reservedFor;
        return metadata;
      });
      await this.metadataService.putMetadatas(metadatas);
    }

    // remove NFTs from service
    const serviceAccount = this.userService.getAccountDetails(serviceAddress);
    const serviceNfts = serviceAccount.nfts || [];
    const newServiceNfts = serviceNfts.filter(nft => nftIds.indexOf(nft) === -1);

    // put NFTs into user
    userNfts.push(...nftIds);

    // TODO transaction somewhere?

    // save stuffs
    this.savePacksData();
    this.userService.putAccountDetails(this.userState.address, {
      ...accountDetails,
      nfts: userNfts,
      packs: userPacks
    });
    this.userService.putAccountDetails(serviceAddress, {
      ...serviceAccount,
      nfts: newServiceNfts
    });

    return nftIds;
  }
  async canBuyPack(saleId: number): Promise<SaleState> {
    if (!this.userState?.loggedIn) {
      return SaleState.LogInRequired;
    }

    const linked = await this.userService.checkIfLinked(true);
    if (!linked) {
      return SaleState.LinkRequired;
    }

    const packSale = this.packsData.packSales.find(sale => sale.saleId === saleId);
    if (!packSale) {
      throw new Error(`PackSale ${saleId} not found`);
    }
    
    if (packSale.packIds.length === 0) {
      return SaleState.OutOfStock;
    }

    const salePrice = new Decimal(packSale.price);
    const balance = new Decimal(this.userState.balance);
    if (balance.lessThan(salePrice)) {
      return SaleState.Unaffordable;
    }

    return SaleState.Available;
  }
  addSales(packSales: PackSale[]): number[] {
    const fixedPackSales: PackSale[] = packSales.map(sale => ({
      ...sale,
      saleId: this.packsData.saleIdPool++
    }));
    fixedPackSales.forEach(sale => {
      sale.packIds.forEach(packId => {
        this.packsData.packMap[packId].saleId = sale.saleId;
      });
    });
    this.packsData.packSales = this.packsData.packSales.concat(fixedPackSales);
    this.savePacksData();
    return fixedPackSales.map(sale => sale.saleId);
  }
  addFeatured(saleId: number): void {
    if (this.packsData.featured.indexOf(saleId) !== -1) { return; }

    this.packsData.featured.push(saleId);
    this.savePacksData();
  }
  removeFeatured(saleId: number): void {
    const index = this.packsData.featured.indexOf(saleId);
    if (index === -1) { return; }

    this.packsData.featured.splice(index, 1);
    this.savePacksData();
  }
  addMidPage(saleId: number): void {
    if (this.packsData.midPage.indexOf(saleId) !== -1) { return; }

    this.packsData.midPage.push(saleId);
    this.savePacksData();
  }
  removeMidPage(saleId: number): void {
    const index = this.packsData.midPage.indexOf(saleId);
    if (index === -1) { return; }

    this.packsData.midPage.splice(index, 1);
    this.savePacksData();
  }
  async createPack(name: string, imgSrc: string, rarity: Rarity, nftIds?: number[]): Promise<Pack> {
    const packId = this.packsData.packIdPool++;
    const pack = {
      id: packId, saleId: -1,
      name, imgSrc, rarity, nftIds,
      opened: false
    };
    this.packsData.packMap[packId] = pack;
    this.savePacksData();

    // Associate NFTs to Pack ID
    // TODO should we even do this?
    if (this.metadataService.putMetadatas) {
      const metadataMap = await this.metadataService.loadNFTMetadata(nftIds, this.userState.address);
      const metadatas = Object.values(metadataMap).map(metadata => {
        metadata.packId = packId.toString();
        metadata.reservedFor = packId.toString();
        return metadata;
      });
      await this.metadataService.putMetadatas(metadatas);
    }

    return pack;
  }
}
