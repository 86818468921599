import React from 'react';
import { SaleState } from '../../common/SaleState';
import { UserAccount } from '../../common/UserAccount';
import { Action } from '../../context/GenerateContext';

export enum Rarity {
  Common = 'Common',
  Rare = 'Rare',
  Epic = 'Epic'
}

export type PackSale = {
  saleId: number,
  name: string,
  imgSrc: string,
  rarity: Rarity,
  available: boolean,
  availableAt: number,
  price: string,
  packIds?: number[] // mainly for mock
};

export type Pack = {
  id: number,
  saleId: number,
  name: string,
  rarity: Rarity,
  imgSrc: string,
  opened: boolean,
  nftIds?: number[] // mainly for mock
};

export interface PacksService {
  setUserDispatch(dispatch: React.Dispatch<Action>): void,
  setUserState(state: UserAccount): void,
  getFeaturedSales(): Promise<PackSale[]>,
  getMidPageSales(): Promise<PackSale[]>,
  getSales(): Promise<PackSale[]>,
  getSingleSale(saleId: number): Promise<PackSale>,
  getPacks(packIds: number[]): Promise<Pack[]>,
  getSinglePack(packId: number): Promise<Pack>,
  buyPack(saleId: number): Promise<number>,
  openPack(id: number): Promise<number[]>,
  canBuyPack(saleId: number): Promise<SaleState>,

  // mainly for mock
  addSales?(packSales: PackSale[]): number[],
  addFeatured?(saleId: number): void,
  removeFeatured?(saleId: number): void,
  addMidPage?(saleId: number): void,
  removeMidPage?(saleId: number): void,
  createPack?(name: string, imgSrc: string, rarity: Rarity, nftIds?: number[]): Promise<Pack>
}
