
export enum UserActionType {
  UpdateAddress = 'user/updateAddress',
  UpdateName = 'user/updateName',
  UpdateChecked = 'user/updateChecked',
  UpdateLinked = 'user/updateLinked',
  UpdateBalance = 'user/updateBalance',
  UpdateChainBalance = 'user/updateChainBalance',
  UpdateNFTs = 'user/updateNFTs',
  UpdatePacks = 'user/updatePacks',
  AddPack = 'user/addPack',
  RemovePack = 'user/removePack',
  UpdatePermissions = 'user/updatePermissions',
  AddPermissions = 'user/addPermissions',
  RemovePermissions = 'user/removePermissions',
  UpdateLoggedIn = 'user/updateLoggedIn',
  LogOut = 'user/logOut',
  LogIn = 'user/logIn'
};
