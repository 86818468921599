import { FclService } from '../fcl/FclService';
import { PackSale } from '../packs/PacksService';
import { AdminService, ProtoMetadata } from './AdminService';

export class FclAdminService implements AdminService {
  constructor(
    private fclService: FclService
  ) {}

  setUserDispatch(dispatch) {
    // do nothing
  }
  setUserState(state) {
    // do nothing
  }
  async linkService(): Promise<void> {
    const tx = await this.fclService.linkService();
    const txObj = await tx.onceSealed();
    if (txObj.errorMessage) {
      throw new Error(txObj.errorMessage);
    }
  }
  async loadServiceSetup(): Promise<Play.ServiceAccountDetails> {
    return this.fclService.loadServiceSetup();
  }
  async mintNFT(name: string, rarity: string, uri: string, packId?: string): Promise<number> {
    const tx = await this.fclService.debugMintNFT(
      name,
      rarity,
      uri,
      packId
    );
    const txObj = await tx.onceSealed();
    if (txObj.errorMessage) {
      throw new Error(txObj.errorMessage);
    }
    const details = await this.loadServiceSetup();
    return details.nfts[details.nfts.length - 1];
  }
  async mintMultiNFT(metadatas: ProtoMetadata[]): Promise<number[]> {
    const tx = await this.fclService.debugMultiMintNFT(metadatas);
    const txObj = await tx.onceSealed();
    if (txObj.errorMessage) {
      throw new Error(txObj.errorMessage);
    }
    const details = await this.loadServiceSetup();
    return details.nfts.slice(-metadatas.length);
  }
  async mintPlayTokens(receiverAddress: string, amount: string): Promise<void> {
    const tx = await this.fclService.debugMintPlayTokens(receiverAddress, amount);
    const txObj = await tx.onceSealed();
    if (txObj.errorMessage) {
      throw new Error(txObj.errorMessage);
    }
  }
  async createPackSale(): Promise<PackSale> {
    throw new Error('Unimplemented method');
  }
}
