import React, { MouseEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUserState } from '../../services/user/UserContext';
import { useUserService } from '../../services/user/UserServiceContext';

import './LoginStatus.scss';

export function LoginStatus() {
  const userState = useUserState();
  const userService = useUserService();
  const navigate = useNavigate();

  function logIn(e: MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    userService.logIn();
  }

  function logOut(e: MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    userService.logOut();
    navigate('/');
  }

  return (
    <div className="login-status ms-3">
      { userState.loggedIn ? (
        <div className="logged-in">
          <a className="btn btn-logout" href="/logout" onClick={logOut}>Disconnect Wallet</a>
        </div>
      ) : (
        <div className="logged-out">
          <a className="btn btn-signup" href="/login" onClick={logIn}>Connect Wallet</a>
        </div>
      )}
    </div>
  );
}
