import { NFTCard, NFTCardSize } from './NFTCard';

export type CardListProps = {
  nftItems: Play.NFTMetadata[],
  cardSize: NFTCardSize,
  baseUrl?: string,
  showPrice?: boolean
}

export function NFTCardList({ nftItems, cardSize, baseUrl = undefined, showPrice = false }: CardListProps) {
  return (
    <div className="w-100">
      <div className="d-flex flex-wrap justify-content-center">
        {nftItems.length === 0 ? (
          <div className="alert alert-info" role="alert">
            Empty.
          </div>
        ) : nftItems.map(nftItem => (
          <NFTCard key={nftItem.id}
            nftMetadata={nftItem}
            size={cardSize}
            showPrice={showPrice}
            baseUrl={baseUrl}
          />
        ))}
      </div>
    </div>
  );
}
