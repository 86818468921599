import * as fcl from '@onflow/fcl';
import * as t from '@onflow/types';
import { SubscriptionLike } from 'rxjs';

import loadServiceCode from '../../cadence/scripts/LoadServiceAccount.cdc';
import loadAccountCode from '../../cadence/scripts/LoadAccount.cdc';
import loadMetadataCode from '../../cadence/scripts/LoadMetadata.cdc';
import loadSaleMetadataCode from '../../cadence/scripts/LoadSaleMetadata.cdc';
import loadSalesCode from '../../cadence/scripts/LoadSales.cdc';

import linkServiceCode from '../../cadence/transactions/LinkServiceAccount.cdc';
import addToMarketCode from '../../cadence/transactions/AddToMarket.cdc';
import linkAccountCode from '../../cadence/transactions/LinkAccount.cdc';
import purchastNFTCode from '../../cadence/transactions/PurchaseNFT.cdc';
import debugMintNFTCode from '../../cadence/transactions/DebugMintNFT.cdc';
import debugMultiMintNFTCode from '../../cadence/transactions/DebugMultiMintNFT.cdc';
import debugMintPlayTokensCode from '../../cadence/transactions/DebugMintPlayTokens.cdc';
import { environment } from '../../environments/environment';

type AddressMap = {[key: string]: string | null}

const addressRegex = /0x[a-z0-9]+/gi;

function addressReducer(accumulator: string[], current: RegExpMatchArray): string[] {
  if (accumulator.indexOf(current[0]) === -1) {
    accumulator.push(current[0]);
  }
  return accumulator;
}

async function resolveAddress(address: string): Promise<[string, string]> {
  const realAddress: string = await fcl.config().get(address, null);
  return [address, realAddress];
}

export class FclService {

  constructor() {
    // window['fcl'] = fcl
  }

  signUp(): void {
    fcl.signUp();
  }

  logIn(): void {
    fcl.logIn();
  }

  logOut(): void {
    fcl.unauthenticate();
  }

  currentUserInterface(): fcl.CurrentUser {
    return fcl.currentUser();
  }

  subscribeToUserChange(fn: (user: fcl.CurrentUserObject) => void): SubscriptionLike {
    return fcl.currentUser().subscribe(fn);
  }

  async getServiceAddress(): Promise<string> {
    return fcl.config().get('0xMainService', environment.fclContractProfile);
  }

  /**
   * Helper function to send scripts.
   * @param code Cadence code. Named addresses will be replaced. 
   * @param args list of ArgumentObjects (value, FCL Type)
   * @returns The value specified in the Cadence code.
   */
  async sendScript(code: string, args: fcl.ArgumentObject[] = undefined): Promise<any> {
    const builders = [] as fcl.Builder[];

    const fCode = await FclService.interpolateAddresses(code);
    builders.push(fcl.script(fCode));

    if (args) { builders.push(fcl.args(args)); }

    const encoded = await fcl.send(builders);
    const decoded = await fcl.decode(encoded);
    return decoded;
  }

  /**
   * Helper function to send transactions. Limited to one authz, and that
   * authz will be used for proposer, payer, and authorizations.
   * @param preInterCode Cadence code. Named addresses will be replaced.
   * @param limit Gas Limit. Default is 100
   * @param authz Authorization function that returns a promise of an Authorization
   *              object. Default is fcl.authz (current user's authz)
   * @returns Transaction utility object
   */
  async sendTx(code: string, authz?: fcl.AuthorizationFunction, args?: fcl.ArgumentObject[], limit = 100): Promise<fcl.TransactionUtility> {
    if (!authz) {
      authz = fcl.authz;
    }

    const fCode = await FclService.interpolateAddresses(code);
    
    const builders = [
      fcl.proposer(authz),
      fcl.payer(authz),
      fcl.authorizations([authz]),
      fcl.limit(limit),
      fcl.transaction(fCode)
    ];

    if (args) { builders.push(fcl.args(args)); }

    const encoded = await fcl.send(builders);
    const transaction = await fcl.tx(encoded);
    return transaction;
  }

  /**
   * Loads the service account setup details.
   * @returns The service account setup details.
   */
  async loadServiceSetup(): Promise<Play.ServiceAccountDetails> {
    const decoded = await this.sendScript(loadServiceCode);
    return decoded;
  }

  /**
   * Setup the service account.
   * @returns A transaction utility object of the link status.
   */
  async linkService(): Promise<fcl.TransactionUtility> {
    const transaction = await this.sendTx(linkServiceCode);
    return transaction;
  }

  async addToMarket(id: number, price: string): Promise<fcl.TransactionUtility> {
    const transaction = await this.sendTx(
      addToMarketCode,
      undefined,
      [
        fcl.arg(id, t.UInt64),
        fcl.arg(price, t.UFix64)
      ]
    );
    return transaction;
  }

  async debugMintNFT(name: string, rarity: string, uri: string, packId?: string): Promise<fcl.TransactionUtility> {
    const args = [
      fcl.arg(name, t.String),
      fcl.arg(rarity, t.String),
      fcl.arg(uri, t.String)
    ];
    if (packId !== undefined) {
      args.push(fcl.arg(packId, t.String));
    }
    const transaction = await this.sendTx(
      debugMintNFTCode,
      undefined,
      args
    );
    return transaction;
  }

  async debugMultiMintNFT(metadatas: ({name: string, rarity: string, uri: string, packId?: string} | null)[]): Promise<fcl.TransactionUtility> {
    const gasLimit = 50 + metadatas.length * 50;
    const transaction = await this.sendTx(
      debugMultiMintNFTCode,
      undefined,
      [
        fcl.arg(
          metadatas.map(FclService.objToCadenceDict), 
          t.Array(t.Optional(t.Dictionary({ key: t.String, value: t.String })))
        )
      ],
      gasLimit
    );
    return transaction;
  }

  async debugMintPlayTokens(address: string, amount: string): Promise<fcl.TransactionUtility> {
    const transaction = await this.sendTx(
      debugMintPlayTokensCode,
      undefined,
      [
        fcl.arg(address, t.Address),
        fcl.arg(amount, t.UFix64)
      ]
    );
    return transaction;
  }

  /**
   * Loads account details.
   * @param address The address of the account to load. If undefined, it will use the
   *                current user's.
   * @returns Account details.
   */
  async loadAccount(address?: string): Promise<Play.AccountDetails> {
    if (!address) {
      const user = await fcl.currentUser().snapshot();
      if (!user?.addr) {
        throw new Error('FclService.loadAccount: current user not logged in');
      }
      address = user.addr;
    }
    const decoded = await this.sendScript(loadAccountCode, [
      fcl.arg(address, t.Address)
    ]);
    return decoded;
  }

  /**
   *  
   * @param authz Authorization function to pass into the transaction. If
   *              undefined, it will use the current account.
   * @returns A transaction utility object.
   */
  async linkAccount(authz?: fcl.AuthorizationFunction): Promise<fcl.TransactionUtility> {
    const transaction = this.sendTx(linkAccountCode, authz);
    return transaction;
  }

  async purchaseNFT(id: number, authz?: fcl.AuthorizationFunction): Promise<fcl.TransactionUtility> {
    const transaction = this.sendTx(purchastNFTCode, authz, [
      fcl.arg(id, t.UInt64)
    ]);
    return transaction;
  }

  async loadMetadata(nftIds: number[], address?: string): Promise<Play.NFTMetadataMap> {
    if (!address) {
      const user = await fcl.currentUser().snapshot();
      if (!user?.addr) {
        throw new Error('FclService.loadAccount: current user not logged in');
      }
      address = user.addr;
    }

    const decoded = await this.sendScript(loadMetadataCode, [
      fcl.arg(address, t.Address),
      fcl.arg(nftIds, t.Array(t.UInt64))
    ]);
    return decoded;
  }

  async loadSaleMetadata(nftIds: number[]): Promise<Play.NFTMetadataMap> {
    const decoded = await this.sendScript(loadSaleMetadataCode, [
      fcl.arg(nftIds, t.Array(t.UInt64))
    ]);
    return decoded;
  }

  async loadSales(): Promise<Play.NFTMetadata[]> {
    const sales = await this.sendScript(loadSalesCode);
    return sales;
  }

  /**
   * Making our own address interpolator since FCL seems to not do this,
   * despite claiming to do so.
   * @param cadence Cadence code with addresses to replace
   * @returns New cadence code with replaced addresses
   */
  static async interpolateAddresses(cadence: string) {
    const matches = [...cadence.matchAll(addressRegex)];
    const addresses = matches.reduce<string[]>(addressReducer, []);
    const promises = addresses.map(address => resolveAddress(address));
    const results = await Promise.all(promises);
    const addressMap: AddressMap = Object.fromEntries(results);
    return cadence.replace(addressRegex, (match) => addressMap[match] ?? match);
  }

  static objToCadenceDict(obj: { [key: string]: any }): { key: string, value: any }[] {
    return Object.entries(obj).map(([key, value]) => ({
      key, value
    }));
  }
}
