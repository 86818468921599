import { Action, generateStoreContext } from '../../context/GenerateContext';
import { UserAccount } from '../../common/UserAccount';
import { UserActionType } from './UserActionType';
import { UserPermission } from '../../common/UserPermission';

const initialState: UserAccount = {
  address: null,
  name: null,
  checked: false,
  linked: false,
  balance: null,
  chainBalance: null,
  nfts: [],
  packs: [],
  permissions: [],
  loggedIn: false
};

function createFilterUnwantedPerms(unwantedPerms: UserPermission[]) {
  return (perm: UserPermission) => unwantedPerms.indexOf(perm) === -1;
}

function reducer(state: UserAccount, action: Action): UserAccount {
  let permissions: UserPermission[], packs: number[], index: number;
  switch (action.type) {
  // case UserActionType.UpdateAll:
  //   const allState = { ...state, ...action };
  //   delete allState.type;
  //   return allState;
  case UserActionType.UpdateAddress:
    return {
      ...state,
      address: action.address
    };
  case UserActionType.UpdateName:
    return {
      ...state,
      name: action.name
    };
  case UserActionType.UpdateChecked:
    return {
      ...state,
      checked: action.checked
    };
  case UserActionType.UpdateLinked:
    return {
      ...state,
      linked: action.linked
    };
  case UserActionType.UpdateBalance:
    return {
      ...state,
      balance: action.balance
    };
  case UserActionType.UpdateChainBalance:
    return {
      ...state,
      chainBalance: action.chainBalance
    };
  case UserActionType.UpdateNFTs:
    return {
      ...state,
      nfts: action.nfts
    };
  case UserActionType.UpdatePacks:
    return {
      ...state,
      packs: action.packs
    };
  case UserActionType.AddPack:
    if (!action.packId) {
      throw new Error(`Missing 'packId' property in '${action.type}' action.`);
    }
    packs = [...state.packs];
    if (packs.indexOf(action.packId) === -1) {
      return state;
    }
    packs.push(action.packId);
    return {
      ...state,
      packs
    };
  case UserActionType.RemovePack:
    if (!action.packId) {
      throw new Error(`Missing 'packId' property in '${action.type}' action.`);
    }
    packs = [...state.packs];
    index = packs.indexOf(action.packId);
    if (index === -1) {
      return state;
    }
    packs.splice(index, 1);
    return {
      ...state,
      packs
    };
  case UserActionType.UpdatePermissions:
    return {
      ...state,
      permissions: action.permissions
    };
  case UserActionType.AddPermissions:
    if (!action.permissions) {
      throw new Error(`Missing 'permissions' property in '${action.type}' action.`);
    }
    if (!Array.isArray(action.permissions)) {
      throw new Error(`Invalid value in 'permissions' property in '${action.type}' action.`);
    }
    permissions = state.permissions || [];
    
    permissions = permissions.concat(
      action.permissions.filter(createFilterUnwantedPerms(permissions)) // avoid duplicates
    );
    return {
      ...state,
      permissions
    };
  case UserActionType.RemovePermissions:
    if (!action.permissions) {
      throw new Error(`Missing 'permissions' property in '${action.type}' action.`);
    }
    if (!Array.isArray(action.permissions)) {
      throw new Error(`Invalid value in 'permissions' property in '${action.type}' action.`);
    }
    permissions = state.permissions || [];
    permissions = permissions.filter(createFilterUnwantedPerms(action.permissions));
    return {
      ...state,
      permissions
    };
  case UserActionType.UpdateLoggedIn:
    return {
      ...state,
      loggedIn: action.loggedIn
    };
  case UserActionType.LogOut:
    return {
      address: null,
      name: null,
      checked: false,
      linked: false,
      balance: null,
      chainBalance: null,
      nfts: [],
      packs: [],
      permissions: [],
      loggedIn: false
    };
  case UserActionType.LogIn:
    return {
      address: action.address,
      name: action.name,
      checked: action.checked ?? false,
      linked: action.linked ?? false,
      balance: action.balance || null,
      chainBalance: action.chainBalance || null,
      nfts: action.nfts || [],
      packs: action.packs || [],
      permissions: action.permissions || [],
      loggedIn: true
    };
  }
}

const {
  StateContext,
  DispatchContext,
  ContextProvider,
  ContextConsumer,
  withContext,
  useContextState,
  useContextDispatch
} = generateStoreContext(reducer, initialState, 'userState', 'userDispatch');

export {
  StateContext as UserStateContext,
  DispatchContext as UserDispatchContext,
  ContextProvider as UserProvider,
  ContextConsumer as UserConsumer,
  withContext as withUser,
  useContextState as useUserState,
  useContextDispatch as useUserDispatch,
  UserActionType
};
